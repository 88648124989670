import React, {useCallback, useState , useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import AppConfig from '../../../config/AppConfig';
import $ from 'jquery';
import axios from 'axios';
import AccountConfig from "../../../config/AccountConfig";
import {
    LoginSocialGoogle,
    IResolveParams,
} from 'reactjs-social-login';
import {
    FacebookLoginButton,
    GoogleLoginButton,
    LinkedInLoginButton,
} from 'react-social-login-buttons';
import RegisterJS from './register';
import SessionConfig from "../../../config/SessionConfig";





export default function Register(props) {

    let navigate = useNavigate();
    useEffect(()=>{
        RegisterJS.form.initializeForm();
        if (AccountConfig.AUTH.check()){
            navigate('/');
        }
    } , []);

    const screenheight = window.innerHeight;
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [isSubscribed , setIsSubscribed] = useState(true);
    const [errorsAccount, seterrorsAccount] = useState([]);

    const handleOnSubmit = (e)=>{
        e.preventDefault();
        window.$('#submitBtn').attr('disabled' ,  true);
        window.$('#submitBtn').text('Loading...');
        window.$('#savingLoadingScreen').show();
        const formData = new FormData();
        formData.append('first_name' , first_name );
        formData.append('last_name' , last_name );
        formData.append('email' , email );
        formData.append('password' , password );
        formData.append('password_confirmation' , password_confirmation );
        axios.post(AppConfig.LINKS.REGISTER, formData)
            .then(function (response) {
                console.log(response);
                console.log(response.data.message);
                window.$('#savingLoadingScreen').hide();
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('REGISTER');
                if(response.data.message === 'OK'){
                    window.$('#resultTxtHolder').hide();
                    window.$('#resultTxt').text('')
                    AccountConfig.AUTH.authenticate(response.data.access_token);
                    SessionConfig.setUser(response.data.user);
                    navigate('/user/profile');
                }

                if(response.data.message === 'BAD'){
                    window.$('#resultTxtHolder').show();
                    window.$('#resultTxt').text(response.data.errors)
                }

            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('REGISTER');
            });

    };

    const handleOnChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'first_name' :
                setFirstName(value);
                break;
            case 'last_name' :
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'password_confirmation':
                setPasswordConfirmation(value);
                break;
        }
    };

    const onSubscribeChecked = (e) => {

        if($('#subscribeBox').is(':checked')){
            setIsSubscribed(false);
        }else {
            setIsSubscribed(true);
        }
    };


    const onLoginStart = useCallback(() => {
    }, []);



    return(
        <div className="login-page" id="login-page" style={{ minHeight: screenheight -99 ,  paddingBottom: 50}}>


            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l10 push-l1    register-form-main-holder">


                        <div className="row equal-height">

                            <div className="col s12 m6 l6   padding-4 min-height-100" style={{  paddingBottom: 70}}  >

                                <p style={{ marginTop: 10, marginBottom: 0, fontWeight: 400, color: '#999'}}>Get Started for free</p>
                                <h4 style={{ marginTop: 0, marginBottom: 1}}>Create a personal account </h4>
                                <p style={{marginTop: 5, marginBottom: 0, fontWeight: 400, color: '#999'}}>Already a member? <Link to="/login" className="green-text">log in</Link></p>


                                <form id="registration-form" style={{ marginTop: 30}} onSubmit={e => handleOnSubmit(e)}>

                                    <div className="row">
                                        <div className="col s12 m6 l6">
                                            <strong>First Name<span className="red-text">*</span></strong>
                                            <div className="custom-input mb-2">
                                                <i className="material-icons dp48">face</i>
                                                <input required type="text" onChange={e => handleOnChange(e)} name="first_name"  id="first_name" placeholder=""/>
                                            </div>
                                        </div>
                                        <div className="col s12 m6 l6">
                                            <strong>Last Name<span className="red-text">*</span></strong>
                                            <div className="custom-input mb-2">
                                                <i className="material-icons dp48">face</i>
                                                <input required type="text" onChange={e => handleOnChange(e)} name="last_name"  id="last_name" placeholder=""/>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col s12">
                                            <strong>Email Address<span className="red-text">*</span></strong>
                                            <div className="custom-input mb-2">
                                                <i className="material-icons dp48">mail_outline</i>
                                                <input required type="email" onChange={e => handleOnChange(e)} name="email" id="email" placeholder="youremail@domain.com..."/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <strong>Password<span className="red-text">*</span></strong>
                                            <div className="custom-input mb-2" style={{ marginBottom: 5}}>
                                                <i className="material-icons dp48">lock_outline</i>
                                                <input required  type="password" onChange={e => handleOnChange(e)} name="password" id="password" placeholder="type your password here..."/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col s12">
                                            <strong>Confirm Password<span className="red-text">*</span></strong>
                                            <div className="custom-input mb-2" style={{ marginBottom: 5}}>
                                                <i className="material-icons dp48">lock_outline</i>
                                                <input required  type="password" onChange={e => handleOnChange(e)} name="password_confirmation"  id="password_confirmation" placeholder="retype your password here..."/>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="row">
                                        <div className="col s12 m12 l12 ">
                                            <p>Subscribe to our Newsletter for regular Going Local updates with hints and tips on promoting your business, local business case studies, special events and best of all you receive our latest offers and deals.</p>
                                            <label>
                                                <input id="subscribeBox" checked={isSubscribed} onChange={e => handleOnChange(e)} onClick={e => onSubscribeChecked(e)}  type="checkbox" name="subscribeBox" className="filled-in"/>
                                                <span>Subscribe to Newsletter</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col s12 m12 l12 mt-1">
                                            <label>
                                                <input required id="agreeBox" checked={true} onChange={e => handleOnChange(e)} type="checkbox" name="subscribeBox" className="filled-in"/>
                                                <span>I agree to <Link to="/terms-of-use" target="_blank" className="green-text keychainify-checked">Terms of Use</Link>
                                                    and <Link to="/terms-and-conditions" target="_blank" className="green-text keychainify-checked">Terms &amp;  Conditions.</Link>
                                                </span>
                                            </label>
                                        </div>
                                    </div>






                                    <div className="card-alert  gradient-45deg-red-pink mt-2" id="resultTxtHolder" style={{ display: 'none'}}>
                                        <div className="card-content white-text">
                                            <i className="material-icons">error</i>
                                            <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">

                                            </p>


                                        </div>

                                    </div>

                                    <button id="submitBtn" className="btn btn-large green width-100 mt-3">REGISTER</button>


                                </form>

                            </div>
                            <div className="social-buttons-container col s12 m6 l6   center-align-sm left-align-lg" style={{ padding: 50}}>

                                <p className="center-align-sm left-align-lg" style={{ marginTop: 10, marginBottom: 10, fontWeight: 400, color: '#000'}}>Or connect with:</p>


                                <LoginSocialGoogle
                                    client_id={AppConfig.SOCIAL_IDS.REACT_APP_GG_APP_ID}
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }: IResolveParams) => {
                                        //setProvider(provider)
                                        //setProfile(data)

                                        console.log(data);
                                    }}
                                    onReject={(err) => {
                                        console.log(err)
                                    }}
                                >
                                    <GoogleLoginButton />
                                </LoginSocialGoogle>

                                <LoginSocialGoogle
                                    client_id={AppConfig.SOCIAL_IDS.REACT_APP_GG_APP_ID}
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }: IResolveParams) => {
                                        //setProvider(provider)
                                        //setProfile(data)

                                        console.log(data);
                                    }}
                                    onReject={(err) => {
                                        console.log(err)
                                    }}
                                >
                                    <FacebookLoginButton />
                                </LoginSocialGoogle>

                                <LoginSocialGoogle
                                    client_id={AppConfig.SOCIAL_IDS.REACT_APP_GG_APP_ID}
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }: IResolveParams) => {
                                        //setProvider(provider)
                                        //setProfile(data)

                                        console.log(data);
                                    }}
                                    onReject={(err) => {
                                        console.log(err)
                                    }}
                                >
                                    <LinkedInLoginButton />
                                </LoginSocialGoogle>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}