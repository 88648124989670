import React, {useState , useEffect} from 'react';
import  axios from 'axios';
import AccountConfig from "../../../../config/AccountConfig";
import SessionConfig from "../../../../config/SessionConfig";
import AppConfig from "../../../../config/AppConfig";
import {useNavigate} from "react-router-dom/index";
import PostAdjs from './postadd';
import  './postadd.css';
import imageTemp600x300 from '../../../../images/image-temp-600x300.jpg';
import { Editor} from '@tinymce/tinymce-react';


export default function PostAddComponent() {

    const navigate = useNavigate();
    const screenheight = window.innerHeight;

    useEffect(()=>{
        if(!AccountConfig.AUTH.check()){
            navigate('/login');
        }

        if(!AccountConfig.AUTH.isVerified()){
            navigate('/unverified');
        }


        PostAdjs.js.initialize();

        window.$('#categoryselection').on("select2:selecting", function(e) {
            setCategory(e.params.args.data.id);
        });

        window.$('#locationselection').on("select2:selecting", function(e) {
            setlocation(e.params.args.data.id);
        });

        window.$('#isNegotiable').change(function () {
            if(window.$(this).is(":checked")){
                window.$('.negotiableitems').show();
                setNegotiable(true);
            }else {
                window.$('.negotiableitems').hide();
                setNegotiable(false);
            }
        });

    } , []);

    const [title , setTitle] = useState("");
    const [category , setCategory] = useState("");
    const [location , setlocation] = useState("");
    const [price , setPrice] = useState(0.00);
    const [minprice , setMinPrice] = useState(0.00);
    const [negotiable , setNegotiable] = useState(0);
    const [condition , setCondition] = useState("");
    const [descriptions , setDescriptions] = useState("");
    const [shipping , setShipping] = useState("");
    const [featureImage, setFeatureImage] = useState(null);
    const [featureImageGallery, setFeatureImageGallery] = useState(null);




    const countChar = (e) => {
        const len =  e.target.value.length;
        window.$('#charNum').text(""+(5000 - len)+" characters left");
    };

    const OnHandleChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'title':
                setTitle(value);
                break;
            case 'price':
                setPrice(value);
                break;
            case 'min_price':
                setMinPrice(value);
                break;
            case 'condition':
                setCondition(value);
                break;
            case 'descriptions':
                setDescriptions(value);
                break;
            case 'shipping':
                setShipping(value);
                break;
            case 'featured_imaged':
                console.log(e.target.files);
                setFeatureImage(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = function (event) {

                    window.$('#imagePrev').attr("src" , event.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                break;


        }
    };



    const OnHandleSubmit = (e)=>{
        e.preventDefault();

        const content = window.$(descriptions);
        const contenttext = window.$(content).text();
        const len =  contenttext.length;

        if(len > 5000){
            alert("Descriptions field must not exceed 5000 characters!");
            return false;
        }

        window.$('#savingLoadingScreen').show();
        const formData = new FormData();
        formData.append('name' , title );
        formData.append('location' ,  location);
        formData.append('category' ,  category);
        formData.append('price' , price );
        formData.append('min_price' , minprice);
        formData.append('negotiable' , negotiable );
        formData.append('condition' , condition  );
        formData.append('descriptions' , descriptions );
        formData.append('shipping' ,  shipping);
        if(featureImage != null ){
            formData.append('feature_image' ,  featureImage);
        }

        if(featureImageGallery != null ){
            formData.append('feature_image_gallery' ,  featureImageGallery);
        }

        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        axios.post(AppConfig.LINKS.ADD_LISTING, formData,config)
            .then(function (response) {
                window.$('#savingLoadingScreen').hide();
                console.log(response);
                if(response.data.status === "OK"){
                    //alert(response.data.message);
                    navigate('/edit-ad/'+response.data.uid, true);
                }
            })
            .catch(function (error) {
                window.$('#savingLoadingScreen').hide();
                console.log(error);
            });
    };

    const handleEditorChange = (e) => {
        setDescriptions(e.target.getContent());
    };


    return(
        <div className="container" style={{ minHeight: screenheight -215 ,  paddingBottom: 50}}>
            <div className="row">
                <div className="col s12">
                    <h2 className="center mt-5 mb-5">Post Ad</h2>
                </div>
            </div>

            <form className="" onSubmit={e => OnHandleSubmit(e)}>
                <div className="row">
                    <div className="col s12 m12 l8 white pt-3 pb-5 pl-2 pr-2">
                        <div className="row">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Enter a title</span>
                                <p className="mt-0 mb-0">Longer and descriptive titles get more visibility!</p>
                                <div className="custom-input no-icon">
                                    <input required type="text" value={title} name="title" id="title" onChange={ e => OnHandleChange(e)}/>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Select category</span>
                                <div className="custom-input no-icon select">
                                    <select required={true}   className="browser-default select2-data-ajax " id="categoryselection" name="category">
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Select Location</span>
                                <div className="custom-input no-icon select">
                                    <select required={true}   className="browser-default select2-data-ajax " id="locationselection" name="location">
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col s12 m5 l4">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Price</span>
                                <div className="custom-input no-icon">
                                    <input style={{ paddingRight: 200}} value={price} required type="text" name="price" id="price" onChange={ e => OnHandleChange(e)}/>
                                    <span style={{ float: 'right' , marginTop: -45 , marginRight: 20}}>AUD</span>
                                </div>
                            </div>
                            <div className="col s12 m7 l8 negotiable-div">
                                <label>
                                    <input type="checkbox" className="filled-in" name="negotiable" id="isNegotiable"/>
                                    <span>Negotiable</span>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-1 negotiableitems" style={{ display: 'none'}}>
                            <div className="col s12 ">
                                <label>
                                    <input type="checkbox" className="filled-in" name="offering_price" id="offering_price" />
                                    <span>Would you like to set a minimum offer amount?</span>
                                </label>
                            </div>
                        </div>
                        <div className="row mt-2 negotiableitems" style={{ display: 'none'}}>
                            <div className="col s12 m5 l4">
                                <div className="custom-input no-icon">

                                    <input type="text" name="min_price" id="min_price" value={minprice} onChange={ e => OnHandleChange(e)}/>
                                    <span style={{ float: 'right' , marginTop: -45 , marginRight: 20}}>AUD</span>
                                </div>
                            </div>
                            <div className="col s12 m7 l6 black-text" style={{ paddingTop: 5}}>
                                <strong>Note:</strong> You will not receive offers/notifications
                                below the minimum offer amount.
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Condition</span>
                                <label style={{ display: 'inline-block' , marginRight: 20, marginLeft: 20}}>
                                    <input required={true} name="condition" type="radio"  value='used' onChange={ e => OnHandleChange(e) } />
                                    <span>Used</span>
                                </label>
                                <label>
                                    <input required={true} name="condition" type="radio" value='new' onChange={ e => OnHandleChange(e) }  />
                                    <span>New</span>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12 m12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Descriptions</span>
                                <br/>
                                <span>Max 5000 characters</span>
                                <Editor
                                    apiKey='8r59yeqi0ckti09lyqp6werj0iyp7dloff5ez9cxa1otewoj'
                                    initialValue=""
                                    init={{
                                        max_chars: 5,
                                        menubar : false,
                                        plugins: 'lists',
                                        toolbar: 'undo redo | bold italic numlist bullist',
                                        paste_as_text: true,
                                        setup: function(editor) {

                                            editor.on('keyup', function(e) {

                                                //console.log('edited. Contents: ' + editor.getContent());
                                                const content = window.$(editor.getContent());
                                                const contenttext = window.$(content).text();
                                                const len =  contenttext.length;

                                                if(len > 5000){
                                                    window.$('#charNum').text("Max characters reached!");
                                                    window.$('#charNum').addClass("red-text");
                                                }else {
                                                    window.$('#charNum').text(""+(5000 - len)+" characters left");
                                                    window.$('#charNum').removeClass("red-text");
                                                }
                                            });

                                        }
                                    }}
                                    onChange={ e => handleEditorChange(e)}
                                />
                                <span id="charNum" style={{ display: 'inline-block' , marginTop: 10}} >5000 characters left</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Shipping</span>
                                <label style={{ display: 'inline-block' , marginRight: 20, marginLeft: 20}}>
                                    <input required={true} name="shipping" type="radio" value='pick-up' onChange={ e => OnHandleChange(e) } />
                                    <span>Pickup Only</span>
                                </label>
                                <label>
                                    <input required={true} name="shipping" type="radio" value='ship-and-pickup' onChange={ e => OnHandleChange(e) }/>
                                    <span>Shipping & Pickup</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col s12 m12 l4   pb-5 pl-2 pr-2">

                        <div className="row grey lighten-5 pt-5">
                            <div className="col s12 pt-6 pb-10">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Ad featured image</span>
                                <p className="mt-0">- For best results, we recommend choosing landscape images.</p>
                                <img src={imageTemp600x300} id="imagePrev" style={{ width: '100%', height: 170, objectFit : 'cover'}}/>
                                <div className="input-file-field-con">

                                    <input required type="file" accept="image/jpg, image/jpeg, image/png"  name="featured_imaged" id="featured_imaged" onChange={ e => OnHandleChange(e)}/>
                                </div>
                            </div>
                        </div>


                        <div className="row mt-5">
                            <div className="col s12 center">
                                <button className="btn btn-large green">SAVE AD</button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>

        </div>
    )
}