import React from 'react';
import './topbar.css'
import {Link} from "react-router-dom";

export default function TopBar(props) {
    return(
        <div className="green hide-on-med-and-down" id="top-bar" style={{ borderBottom: '1px solid #f2f2f2', padding: '5px 0px'}}>
            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l6 ">
                        <ul className="mt-0 mb-0 top-bar-left">
                            <li className=""> <a href="https://goinglocl.com.au"> DIRECTORY</a></li>
                            <li className=""> <a href="#"> MAGAZINE</a></li>
                            <li className=""> <a href="#"> BUSINESS</a></li>
                        </ul>
                    </div>
                    <div className="col s12 m12 l6">
                        <ul className="mt-0 mb-0 top-bar-right right-align-lg">

                            <li className=""> <Link to="/login"> SIGN IN</Link></li>
                            <li className=""> <Link to="/register"> REGISTER</Link></li>
                            <li className=""> <a href="/"> <i className="material-icons flex">home</i></a></li>
                            <li className=""> <a href="/"> <i className="material-icons flex">help</i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}