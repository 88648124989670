import $ from 'jquery';

$(window).scroll(function(){
    var sticky = $('#header'),
        scroll = $(window).scrollTop();

    if (scroll >= 35) sticky.addClass('fixed');
    else sticky.removeClass('fixed');
});

