import React from "react";
import AccountConfig from '../../../config/AccountConfig';
import SessionConfig from '../../../config/SessionConfig';
import AppConfig from '../../../config/AppConfig';

import axios from 'axios';

export default function UnverifiedEmail(props) {

    const screenheight = window.innerHeight;

    const HandleClick = (e)=>{
        e.preventDefault();

        window.$("#resendLink").prop('disabled', true);
        window.$("#resendLink").off('click');
        window.$("#resendLink").text("Sending please wait...");
        const formData = new FormData();
        formData.append('userID' , AccountConfig.AUTH.user().id);
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        axios.post(AppConfig.LINKS.RESEND_VERIFICATION_EMAIL, formData,config)
            .then(function (response) {
                console.log(response);
                window.$(".resenttxt").hide();
                window.$(".reulttxt").text('A fresh verification link has been sent to your email address.');
            })
            .catch(function (error) {
                console.log(error);
                window.$(".resenttxt").hide();
            });

    };

    return(
        <div className="container" id="unverifiedPage" style={{ minHeight: screenheight -214 , paddingTop: 50, paddingBottom: 50}}>
            <div className="row">
                <div className="col s12 m12 l12 center">
                    <h4>Verify Your Email Address</h4>
                    <p>Before proceeding, please check your email for a verification link.<br/> <span className="resenttxt">If you did not receive the email, <a href="#" id="resendLink" className="green-text" onClick={e => HandleClick(e)}>click here to request another.</a></span></p>
                    <p className="reulttxt black-text"></p>

                    <p className="mt-3">Please reload this page if you already verify your email.</p>
                </div>
            </div>
        </div>
    )
}