import React from 'react';
import './_header.css';
import './_header';
import {Link} from "react-router-dom";

export default function Header(props) {
    return(
        <header>
            <h3 className="mb-1">{props.page.name}</h3>
            <div className="">
                {
                    Array(props.breadcrumbs.length).fill(1).map((el, i) =>
                        <Link key ={i} className="breadcrumb" to={props.breadcrumbs[i].link} >{props.breadcrumbs[i].name}</Link>
                    )
                }
            </div>
        </header>
    )
}