import React from 'react';
import './AdListItem.css';
import AppConfig from "../../../config/AppConfig";
import {Link, useNavigate} from 'react-router-dom';

export default function AdListItem(props) {

    //item

    let navigate = useNavigate();

    function truncate(input) {
        if (input.length > 30) {
            return input.substring(0, 30) + '...';
        }
        return input;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const OnItemClick = (e, slug)=>{
        navigate('/'+slug , true);
    };


    return(
        <div className="ad-list-item" onClick={e => OnItemClick(e, props.item.slug)}>
            <img className="" src={AppConfig.ASSET_LINK+props.item.feature_image}/>
            <p className="ad-item-details">
                <span className="name display-block mt-0">{truncate(props.item.name)}</span>
                <span className="price  display-block mt-2 font-weight-600">${numberWithCommas(props.item.price)} AUD { props.item.negotiable === 1 ? <small className="white-text green padding-1 border-radius-3">Negotiable</small> : ''}</span>
                <span className="location display-block mt-2" ><i className="material-icons flex " style={{ fontSize: 18}} >location_on</i> {props.item.location}</span>
            </p>

        </div>
    )
}