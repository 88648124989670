import React, {useEffect , useState} from 'react';
import './search-page-result.css';
import SearchpagejS from './search-page-result';
import SliderImage3 from '../../../images/goinglocal-marketplace-header-slider-image-5.jpg';
import SearchBar from '../../components/searchbar/SearchBarComponent';
import SearchList from './search-result-list/SearchResultListComponent';
import {Link, useS} from 'react-router-dom';
import axios from "axios/index";
import AppConfig from "../../../config/AppConfig";

export default function SearchPageResult() {

    const queryParams = new URLSearchParams(window.location.search);
    const _category = queryParams.get('category');
    const _name = queryParams.get('name');
    const _location = queryParams.get('location');
    const _page = queryParams.get('page');

    const [isLoading , setIsloading] = useState(true);
    const [searchResult, setSearchResults] = useState([]);

    const [p_currentPage , set_p_currentPage] = useState(1);
    const [p_lastPage , set_p_lastPage] = useState(1);
    const [p_totalResuts , set_p_totalResuts] = useState(0);

    const _url = '/search?category='+_category+'&&name='+_name+'&&location='+_location;

    useEffect(()=>{
        searchListing(_category, _name , _location);
    } , window.location.search);

    function searchListing(category, key, location) {
        axios.get(AppConfig.LINKS.SEARCH_LISTING+"?category="+category+"&&key="+key+"&&location="+location+""+(_page != null ? '&&page='+_page : ''))
            .then(function (response) {
                console.log(response.data);
                setSearchResults(response.data.data.data);
                set_p_currentPage(response.data.data.current_page);
                set_p_lastPage(response.data.data.last_page);
                set_p_totalResuts(response.data.data.total);
                setIsloading(false);
            })
            .catch(function (e) {
                console.log(e);
                setIsloading(false);
            });
    }



    return(
        <div id="search-page-result">
            <div className="container">

                <div className="row">
                    <div className="col s12 m12 l9">
                        <div className="mt-5" >
                            <div className="row">
                                <div className="col s12 m12 l12 ">
                                    <h5 className="black-text">Buy more, spend less with</h5>
                                    <h3 className="black-text">Going<span className="green-text">local™</span> Marketplace</h3>
                                </div>
                            </div>
                            <div className="row mt-1 ">
                                <div className="col s12 pl-3 pr-3">
                                    <SearchBar/>
                                </div>
                                <div className="col s12 black-text">
                                    <p>
                                        {p_totalResuts} Results : '{_name}' in {_location}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col s12 m12 l9">
                        <div className="result-list-container">
                            {
                                !isLoading ? <SearchList list={searchResult}/> : ''
                            }

                        </div>

                        <ul className="pagination mt-3" >
                            <li className={" "+(p_currentPage === 1 ? "disabled" : "" )}>
                                <a  href={(p_currentPage > 1 ? _url+"&&page="+(p_currentPage-1) : "?page="+(p_currentPage))} ><i className="material-icons flex">chevron_left</i></a>
                            </li>

                            {Array(p_lastPage).fill(1).map((el, i) =>
                                <li key={i} className={"waves-effect "+(p_currentPage === (i+1) ? "active" : "" )}><a href={_url+"&&page="+(i+1)}>{i+1} </a></li>
                            )}


                            <li className={""+(p_currentPage === p_lastPage ? "disabled" : "" )}>
                                <a href={(p_currentPage < p_lastPage ? _url+"&&page="+(parseInt(p_currentPage+1)) : _url+"&&page="+(p_currentPage))}><i className="material-icons flex">chevron_right</i></a>
                            </li>
                        </ul>
                    </div>
                </div>








            </div>
        </div>
    )
}