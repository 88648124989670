import AppConfig from '../../../config/AppConfig';

const PostAddJs = {
    'js' : {
        initialize : ()=>{



            window.$('.collapsible').collapsible({
                accordion:true
            });


            /*window.$(document).click(function(e) {

                if( e.target.id !== 'category-list-main-holder' && !window.$('.category-selection-holder').hasClass('hide')) {
                    window.$('.category-selection-holder').addClass("hide");
                    window.$('#select-category-trigger i').css({'transform' : 'rotate('+ 0 +'deg)'});
                }

            });*/



            let rotated = false;
            window.$('#select-category-trigger').click(function () {

                window.$('.location-result-holder').addClass('hide');
                window.$('.keyword-result-holder').addClass('hide');

                const elem = window.$(this).find('i.right');

                window.$({rotation: 90*rotated}).animate({rotation: 90*!rotated}, {
                    duration: 300,
                    step: function(now) {
                        window.$(elem).css({'transform' : 'rotate('+ now +'deg)'});
                    },

                });

                rotated=!rotated;

                if(window.$('.category-selection-holder').hasClass('hide')){
                    window.$('.category-selection-holder').removeClass("hide");
                    window.$('#yourElement').addClass('animated fadeIn');
                }else {
                    window.$('.category-selection-holder').addClass("hide");
                    window.$('#yourElement').removeClass('animated fadeIn');
                }


            });





            window.$.ajax({
                url : AppConfig.LINKS.GET_CATEGORIES_LIST_BY_GROUP,
                type: 'get',
                success : function (response) {
                    //console.log(response);
                    window.$("#sb-categoryselection").select2({
                        dropdownAutoWidth: true,
                        width: '100%',
                        data: JSON.parse(response),
                        placeholder: 'Search category by name...',
                        templateResult : formatState,
                        templateSelection: formatSelection
                    });
                },
                error: function (e) {
                    console.log(e);
                }
            });


            window.$("#locationselection").select2({
                minimumInputLength: 2,
                placeholder: 'Suburb or Postcode',
                ajax: {

                    url : AppConfig.LINKS.GET_LOCATIONS,
                    dataType: "json",
                    type: "GET",
                    data: function (params) {

                        const queryParameters = {
                            key: params.term
                        };
                        return queryParameters;
                    },
                    processResults: function (data) {
                        //console.log(data)
                        return {
                            results: window.$.map(data, function (item) {
                                return {
                                    text: item.text,
                                    id: item.id,
                                    state: item.state,
                                    postcode: item.postcode
                                }
                            })
                        };
                    }
                }
            });





            window.$('#keyword-search-input').focus(function () {
                window.$('.keyword-result-holder').removeClass('hide');
                window.$('.category-selection-holder').addClass("hide");
                window.$('.location-result-holder').addClass("hide");

                window.$('#select-category-trigger i').css({'transform' : 'rotate('+ 0 +'deg)'});
            });


            window.$('#location-search-input').focus(function () {
                window.$('.location-result-holder').removeClass('hide');
                window.$('.category-selection-holder').addClass("hide");
                window.$('.keyword-result-holder').addClass('hide');
                window.$('#select-category-trigger i').css({'transform' : 'rotate('+ 0 +'deg)'});
            });















        }
    }
};

function init() {

    window.$(document).find('.catItemParent').mouseenter(function () {
        window.$(this).removeClass('z-depth-0');

        window.$(this).mouseleave(function () {
            window.$(this).addClass('z-depth-0');
        });
    });

    window.$(document).find('.catItemParent').click(function () {
        const catp = window.$(this).data('parent');
        alert(catp.parent.name);
    });
}

function formatState (state) {
    if (!state.id) {
        return state.text;
    }
    var $state = window.$(
        '<span class="green-text parent-category"> '+(state.content.icon != null ? '<i class="material-icons">'+state.content.icon+'</i>' : "")+' ' + state.text + '</span>'
    );

    var $stat2 = window.$(
        '<span> ' + state.text + '</span>'
    );

    if(state.content.icon != null){
        return $state;
    }else {
        return $stat2;
    }



};


function formatSelection(state) {
    if (!state.id) {
        return state.text;
    }



    var $state = window.$(
        '<span class="green-text"> '+(state.parent.icon != null ? '<i class="material-icons">'+state.parent.icon+'</i>' : "")+' ' + state.parent.name + ' <span class="grey-text">• ' + state.text + '</span></span>'
    );

    return $state;
}


function getRotationDegrees(obj) {
    var matrix = obj.css("-webkit-transform") ||
        obj.css("-moz-transform")    ||
        obj.css("-ms-transform")     ||
        obj.css("-o-transform")      ||
        obj.css("transform");
    if(matrix !== 'none') {
        var values = matrix.split('(')[1].split(')')[0].split(',');
        var a = values[0];
        var b = values[1];
        var angle = Math.round(Math.atan2(b, a) * (180/Math.PI));
    } else { var angle = 0; }
    return (angle < 0) ? angle + 360 : angle;
}




export default PostAddJs;