const SettingsJS = {
    'form' : {
        initializeForm : ()=>{
            window.$("#change-password-form").validate({
                rules: {
                    password: {
                        required: true,
                    },
                    new_password: {
                        required: true,
                        minlength: 8,
                    },

                    confirm_password: {
                        required: true,
                        minlength: 8,
                        equalTo: "#new_password"
                    },

                },
                messages: {
                    confirm_password:{
                        equalTo: "Your passwords don't match!",
                        minlength: "Please enter at least 8 characters."
                    },
                },

                errorElement : 'div',
                errorPlacement: function(error, element) {
                    const placement = window.$(element).data('error red-text');
                    if (placement) {
                        window.$(placement).append(error)
                    } else {
                        error.insertAfter(element);
                    }
                }
            });
        }
    }
};

export default SettingsJS;