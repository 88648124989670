import React, {useEffect , useState} from 'react';
import './searchlistitem.css';
import AppConfig from '../../../../../config/AppConfig';

import Image1 from '../../../../../images/image-temp-600x300.jpg'
import {Link} from "react-router-dom";

export default function SearchListItem(props) {

    const [listing , setListing] = useState(props.listing);

    function truncate(input, num) {
        if (input.length > num) {
            return input.substring(0, num) + '...';
        }
        return input;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function removetags(x) {
        return x.replace(/(<([^>]+)>)/gi, "");

    }

    return(
        <div className="search-list-item mt-2">
            <div className="row">
                <div className="col s12 m3 l3">
                    <Link to={'/'+listing.slug}><img src={AppConfig.ASSET_LINK+listing.feature_image} width="100%"/></Link>
                </div>
                <div className="col s12 m9 l9">
                    <h6 className="mt-0"><Link className="black-text" to={'/'+listing.slug}>{truncate(listing.name , 70)}</Link></h6>
                    <span>${numberWithCommas(listing.price)} AUD { listing.negotiable === 1 ? <small style={{ padding: 2}} className="white-text green border-radius-3">Negotiable</small> : ''}</span>
                    <div className="pt-1 mb-1" dangerouslySetInnerHTML={ {__html: truncate(removetags(listing.descriptions ,) , 150)} }/>

                    <Link to={'/'+listing.slug} className="green-text">Read More</Link>

                </div>
            </div>
        </div>
    )
}
