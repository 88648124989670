import React from 'react';
import Logo from '../../../images/logo-hd-black.png';
import './footer.css';
import {Link} from 'react-router-dom';

export default function Footer(props) {
    return(
        <footer id="footer" className="white z-depth-1">
            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l2">
                        <p><Link to={'/'}><img src={Logo} width={133}/></Link></p>
                    </div>
                    <div className="col s12 m12 l8 center">
                        <p>Copyright © 2021 - 2022 Going Local All rights reserved.</p>
                    </div>
                    <div className="col s12 m12 l2 right-align-lg center-align-sm">
                        <Link to="/404" className="btn btn-large green">POST AN AD</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}