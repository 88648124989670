import React from 'react';

import Logo from '../../../images/logo-hd-black.png';
import UserIcon from '../../../images/usericon.png';
import './header.css';
import './header';
import {Link, useNavigate} from 'react-router-dom';
import AccountConfig from '../../../config/AccountConfig';

export default function Header(props) {

    let navigate = useNavigate();

    const logout = (e)=>{
        e.preventDefault();
        AccountConfig.AUTH.destroy();
        navigate('/login');
    };

    return(

        <header className="page-topbar white " id="header">
            <div className="container">
                <div className="navbar">
                    <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light white">
                        <div className="nav-wrapper">
                            <ul className="left">
                                <li>
                                    <Link to="/" data-target="mobile-demo" className="sidenav-trigger hide-on-large-only"><i className="material-icons mobile-trigger-icon">menu</i></Link>
                                    <h1 className="logo-wrapper"><Link className="brand-logo darken-1" to="/"><img src={Logo} alt="goinglocal"/></Link></h1>
                                </li>
                            </ul>
                            {/*<div className="header-search-wrapper hide-on-med-and-down">
                                <i className="material-icons">search</i>


                                <input className="header-search-input z-depth-2" type="text" name="Search" placeholder="I'm looking for..." data-search="template-list"/>
                            </div>*/}
                            <ul className="navbar-list right">
                                <li className="hide-on-small-and-down"> <a href="#"> HOME</a></li>
                                <li className="hide-on-small-and-down"> <a href="#"> ABOUT US</a></li>
                                <li className="hide-on-small-and-down"> <a href="#"> OTHER SERVICE</a></li>
                                <li className="hide-on-small-and-down"> <a href="#"> CONTACT US</a></li>
                                <li className="hide-on-med-and-down"><Link to={'/post-ad'} className="btn green white-text">POST AN AD</Link> </li>
                                <li className="hide-on-large-only"><a className="waves-effect waves-block waves-light search-button" href=""><i className="material-icons">search </i></a></li>
                                <li><a className="waves-effect waves-block waves-light profile-button" href="" data-target="profile-dropdown"><span className="avatar-status avatar-online"><img src={UserIcon} alt="avatar"/><i></i></span></a></li>
                            </ul>



                            <ul className="dropdown-content" id="profile-dropdown">
                                <li><Link className="grey-text text-darken-1" to="/user/profile"><i className="material-icons">person_outline</i> Profile</Link></li>
                                <li><Link className="grey-text text-darken-1" to="/user/settings"><i className="material-icons">settings</i> Settings</Link></li>
                                <li className="divider"></li>

                                <li><a className="grey-text text-darken-1" href="page-faq.html"><i className="material-icons">help_outline</i> Help</a></li>
                                <li><a className="grey-text text-darken-1" onClick={e => logout(e)} ><i className="material-icons">keyboard_tab</i> Logout</a></li>
                            </ul>


                            <ul className="sidenav" id="mobile-demo">
                                <li className=""> <a href="#"> ABOUT US</a></li>
                                <li className=""> <a href="#"> OTHER SERVICE</a></li>
                                <li className=""> <a href="#"> CONTACT US</a></li>
                                <li className=""> <a href="#"> SIGN IN</a></li>
                                <li className=""> <a href="#"> REGISTER</a></li>
                            </ul>




                        </div>
                        <nav className="display-none search-sm">
                            <div className="nav-wrapper">
                                <form id="navbarForm">
                                    <div className="input-field search-input-sm">
                                        <input className="search-box-sm" type="search" required="" id="search" placeholder="What are you looking for..." data-search="template-list"/>
                                        <label className="label-icon" htmlFor="search"><i className="material-icons search-sm-icon">search</i></label><i className="material-icons search-sm-close">close</i>
                                    </div>
                                </form>


                            </div>
                        </nav>


                    </nav>
                </div>
            </div>

            <div className="valign-wrapper" id="savingLoadingScreen" style={{ display: 'none'}}>
                <h5 className="center white-text" style={{ margin: 'auto'}}>Loading please wait...</h5>
            </div>

        </header>

    )
}