import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Page404 from './app/frontend/404/404.Component';
import Home from './app/frontend/homepage/homepage';
import TopBar from './app/frontend/topbar/TopBarComponent';


import Header from './app/frontend/header/HeaderComponent';
import Footer from './app/frontend/footer/footer';
import Login from './app/auth/login/LoginComponent';
import Register from './app/auth/register/RegisterComponent';
import ForgotPassword from './app/auth/forgetpassword/ForgotPassword.Componnent';
import ResetPassword from './app/auth/resetpassword/ResetPassword.Componnent';
import Profile from './app/backend/pages/profile/ProfileComponent';
import AccountSettings from './app/backend/pages/settings/SettingsComponent';
import UnverifiedEmail from './app/auth/confirmemail/UnverifiedEmailComponent';
import MyListingPage from './app/backend/pages/mylistings/MyListingComponent';
import PostAd from './app/backend/pages/postadd/PostAddComponent';
import EditAd from './app/backend/pages/edit-ad/EditAdComponent';
import Loading from './app/components/LoadingScreen';
import PreviewAd from './app/backend/pages/preview-ad/PreviewAdComponent3';
import SingleAdComponent from './app/frontend/ad/SingleAdComponent';
import SearchPageResult from './app/frontend/search-page/SearchPageResultComponent';


import ScrollToTop from './app/components/ScrollToTop';




function App() {






    return(
        <BrowserRouter>

            <ScrollToTop/>

            <TopBar/>
            <Header/>

            <Routes>

                {/*=========Frontend===========*/}
                <Route exact path="*" element={<Page404/>} />
                <Route exact path="/404" element={<Page404/>} />
                <Route  path="/" element={<Home/>} />
                {/*============================*/}

                {/*======Authentications=====*/}
                <Route  path="/login" element={<Login/>} />
                <Route  path="/register" element={<Register/>} />
                <Route  path="/forgot-password" element={<ForgotPassword/>} />
                <Route  path="/reset-password/" element={<ResetPassword/>} />
                <Route  path="/unverified/" element={<UnverifiedEmail/>} />
                {/*==========================*/}

                {/*======Backend=============*/}
                <Route  path="/user/profile" element={<Profile/>} />
                <Route  path="/user/profile/:verified/:token" element={<Profile/>} />
                <Route  path="/user/settings" element={<AccountSettings/>} />
                <Route  path="/user/ads" element={<MyListingPage/>} />
                <Route  path="/user/ads/:status" element={<MyListingPage/>} />
                <Route  path="/post-ad/" element={<PostAd/>} />
                <Route  path="/edit-ad/:uid" element={<EditAd/>} />
                <Route  path="/loading" element={<Loading/>} />
                <Route  path="/preview/:adid" element={<PreviewAd/>}/>
                <Route  path="/:slug" element={<SingleAdComponent/>}/>
                <Route  path="/search/" element={<SearchPageResult/>}/>

            </Routes>

            <Footer/>

        </BrowserRouter>
    )
}

export default App;