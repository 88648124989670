import React from 'react';
import ImageTemp from '../../../../images/Commercial.jpg';
import './related-ads.css';
import {Link} from "react-router-dom";
import AppConfig from '../../../../config/AppConfig';

export default function RelatedAdsComponent(props) {
    return(
        <div className="row mt-6" id="related-ads">

            <div className="row">
                <div className="col s12">
                    <h5>Related ads</h5>

                    <div className="divider mt-2" />
                </div>

            </div>



            <div className="row mt-2">
                {
                    Array(props.relatedAds.length).fill(1).map((el, i) =>
                        <div className="col s12 m3 l3  " style={{ borderRadius: 10}} key={i}>
                            <div className="related-ad-item">
                                <Link to={props.preview ? '/'+props.relatedAds[i].slug : '/'+props.relatedAds[i].slug}>
                                    <img src={AppConfig.ASSET_LINK+props.relatedAds[i].feature_image} width="100%" />
                                    <p className="green-text mt-1 mb-0"><i className="material-icons flex">location_on</i> {props.relatedAds[i].location}</p>
                                    <h6 className="padding-2 mt-0">{props.relatedAds[i].name}</h6>
                                </Link>
                            </div>
                        </div>
                    )
                }




            </div>

            {
                props.relatedAds.length > 4 ?
                    <div className="row mt-4">
                        <div className="col s12 center">
                            <Link to={"#"}><button className="btn btn-flat green white-text">SEE MORE</button></Link>
                        </div>

                    </div>
                    : ""
            }







        </div>
    )
}