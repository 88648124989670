import React,{useState} from 'react';
import {Link} from "react-router-dom";
import logoBlack from '../../../images/logo-hd-black.png'
import axios from "axios/index";
import AppConfig from "../../../config/AppConfig";


export default function ForgotPassword(props) {


    const [email, setEmail] = useState("");
    const screenheight = window.innerHeight;

    const handleOnChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'email':
                setEmail(value);
                break;
        }

    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        window.$('#submitBtn').attr('disabled' ,  true);
        window.$('#submitBtn').text('Loading...');

        axios.post(AppConfig.LINKS.FORGOT_PASSWORD, {
            email: email
        })
            .then(function (response) {
                //console.log(response);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('Send Password Reset Link');
                window.$('#resultTxt').text(response.data.content);

            })
            .catch(function (error) {
                //console.log(error);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('Send Password Reset Link');
            });

    };

    return(
        <div className="container" id="register-page" style={{ minHeight: screenheight -214 ,  paddingBottom: 50, paddingTop: 50}}>

            <div className="row">
                <div className="col s12 m12 l4 push-l4  m6 push-m3 ">


                    <h4 className="center" style={{ marginTop: 0, marginBottom: 1}}>Forgot Password? </h4>
                    <p  className=" center" style={{marginTop: 5, marginBottom: 0, fontWeight: 400, color: '#999'}}>Don't have an account? <Link to="/register" className="green-text">Register here</Link></p>

                    <form style={{ marginTop: 30}} onSubmit={e => handleOnSubmit(e)}>

                        <strong>Email Address</strong>
                        <div className="custom-input">
                            <i className="material-icons dp48">mail_outline</i>
                            <input required type="email" onChange={e => handleOnChange(e)} name="email" placeholder="youremail@domain.com..."/>
                        </div>
                        <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0}} className="black-text center"> </p>
                        <br/>
                        <button id="submitBtn" className="btn btn-large green width-100">Send password reset link</button>
                    </form>
                </div>
            </div>

        </div>
    )
}