import React from 'react';

import error2png from '../../../images/error-2.png';
import {Link} from "react-router-dom";

export default function Page404 (props) {

    return(
        <div className="row white" style={{ paddingTop: 40}}>
            <div className="col s12">
                <div className="container">
                    <div className="section section-404 p-0 m-0 height-100vh valign-wrapper">
                        <div className="row" style={{ margin: 'auto'}}>
                            <div className="col s12 center-align ">
                                <img src={error2png} className="bg-image-404" alt="" style={{ maxWidth: 700}}/>
                                    <h1 className="error-code m-0">404</h1>
                                    <h6 className="mb-2">BAD REQUEST</h6>
                                    <Link to="/" className="btn waves-effect waves-light gradient-45deg-green-teal gradient-shadow mb-4" >
                                        Back TO Home
                                    </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    )
}