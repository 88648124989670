import React from 'react';
import UserIcon from '../../../../images/usericon.png';

export default function UserProfile(props) {
    return(
        <div className="profile center">
            <img className="circle" src={UserIcon} style={{ width: 100}}/>
            <h5 className="mb-0 mt-0">Arvin Margallo</h5>
            <p className="mt-3 mb-10">margalloarvin866@gmail.com</p>
        </div>
    )
}