import React, {useState, useEffect} from 'react';
import Header from '../../header/_headerComponent';
import SideNav from '../../sidenav/_sidenavComponent';
import {useNavigate} from 'react-router-dom';
import AccountConfig from "../../../../config/AccountConfig";
import SessionConfig from '../../../../config/SessionConfig';
import AppConfig from '../../../../config/AppConfig';
import SettingJs from './settings';
import axios from "axios/index";

export default function Settings(props) {

    const navigate = useNavigate();
    useEffect(()=>{

        if(!AccountConfig.AUTH.check()){
            navigate('/login');
        }

        if(!AccountConfig.AUTH.isVerified()){
            navigate('/unverified');
        }

        SettingJs.form.initializeForm();
    } , []);


    const page = {"page" : 'settings' , "name" : 'Account Settings' };
    const bc = [{'name' : 'Home' , 'link' : '/'} ,{'name' : 'Account Settings' , 'link' : '/user/settings'}];

    const [password , setPassword] = useState("");
    const [newpassword , setnewpassword] = useState("");
    const [confirmpassword , setconfirmpassword] = useState("");

    const onSubmit = (e)=>{
        e.preventDefault();
        window.$('#savingLoadingScreen').show();
        const formData = new FormData();
        formData.append('current_password' , password );
        formData.append('password' , newpassword );
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        axios.post(AppConfig.LINKS.UPDATE_PROFILE_PASSWORD , formData , config)
            .then(function (response) {
                console.log(response);
                window.$('#savingLoadingScreen').hide();
                window.$('.notifInfos').hide();
                if(response.data.message === "OK"){
                    window.$('#successInfo').show();
                    window.$('#errorInfo').hide();
                }

                if(response.data.message === "BAD"){
                    window.$('#errorInfo').show();
                    window.$('#error_resultTxt').text(response.data.error);

                }

            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
                window.$('.notifInfos').hide();
            });
    };

    const onChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'password' :
                setPassword(value);
                break;
            case 'new_password':
                setnewpassword(value)
                break;
            case 'confirm_password':
                setconfirmpassword(value);
                break;
        }
    };




    return(
        <div className="container" style={{ paddingTop: 20 , paddingBottom: 50}}>
            <div className="row">
                <div className="col s12 m4 l3">
                    <SideNav page={page} />
                </div>
                <div className="col s12 m8 l9">
                    <Header page={page} breadcrumbs={bc}/>
                    <form onSubmit={e => onSubmit(e)} id="change-password-form">
                        <div className="card mt-3">

                            <div className="card-content">
                                <span className="card-title mb-3">Update Password</span>
                                <div className="row">
                                    <div className="col s12 m12 l12">
                                        <strong>Current password<span className="red-text">*</span></strong>
                                        <div className="custom-input no-icon">
                                            <input required type="password" onChange={ e => onChange(e)}  value={password} name="password" id="password"/>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-2">
                                    <div className="col s12 m6 l6">
                                        <strong>New password<span className="red-text">*</span></strong>
                                        <div className="custom-input no-icon">
                                            <input required type="password" onChange={ e => onChange(e)} value={newpassword} name="new_password" id="new_password"/>
                                        </div>
                                    </div>
                                    <div className="col s12 m6 l6">
                                        <strong>Confirm password<span className="red-text">*</span></strong>
                                        <div className="custom-input no-icon">
                                            <input required type="password" onChange={ e => onChange(e)} value={confirmpassword} name="confirm_password" id="confirm_password"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col s12 m12 l6">
                                        <div className="mt-3">

                                            <div className="mb-3 card-alert  gradient-45deg-green-teal mt-2 notifInfos" id="successInfo" style={{ display: 'none'}}>
                                                <div className="card-content white-text">
                                                    <i className="material-icons">check</i>
                                                    <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">
                                                        Changes have been saved.
                                                    </p>
                                                </div>

                                            </div>

                                            <div className="mb-3 card-alert  gradient-45deg-red-pink mt-2 notifInfos" id="errorInfo" style={{ display: 'none'}}>
                                                <div className="card-content white-text">
                                                    <i className="material-icons">error</i>
                                                    <p id="error_resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">
                                                        Error occurred when updating profile.
                                                    </p>
                                                </div>

                                            </div>

                                            <button className="btn btn-large green z-depth-0">SAVE CHANGES</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </form>
                </div>
            </div>
        </div>
    )
}