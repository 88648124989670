
const PreviewJS = {
    'js' : {
        initialize : ()=>{

            window.$('.tabs').tabs();
            const imageholderW = window.$('.cover-container').css('width');
            window.$('.bg-blur-holder').css('width', imageholderW);
            window.$('.bg-blur-holder span').css('width', imageholderW);
            window.$('.media-actions-container').css('width', imageholderW);
            window.$('.feature-image').css('width', imageholderW);

        }
    }
};


export default PreviewJS;