import React from 'react';
import { ReactSession  } from 'react-client-session';

const token = "ACCESS_TOKEN";
const user = "USER_DATA";
const user_mail_verification_notified = "USER_MAIL_NOTIFIED";
const post_add_title = "POST_ADD_TITLE";
const post_add_category = "POST_ADD_CATEGORY";

ReactSession.setStoreType("cookie");

let SessionConfig = {

    getToken: ()=> {
        try {
            return ReactSession.get(token);
        } catch (e) {
            return null;
        }
    },

    setToken: (_token)=> {
        ReactSession.set(token, _token);
    },

    destroy_token : ()=>{
        ReactSession.remove(token);
    },

    setUser: (_user)=>{
        ReactSession.set(user, _user);
    },

    getUser: ()=>{
        try {
            return ReactSession.get(user);
        } catch (e) {
            return null;
        }
    },

    setUserVerificationNotified: ()=>{
        ReactSession.set(user_mail_verification_notified, true);
    },

    isUserMailVerificationNotified : ()=>{
        try {
            return ReactSession.get(user_mail_verification_notified);
        } catch (e) {
            return false;
        }
    },

    setPostAddTitle : (title)=>{
        ReactSession.set(post_add_title, title);
    },

    getPostAddTitle : ()=>{

        try {
            return ReactSession.get(post_add_title);
        } catch (e) {
            return null;
        }
    },

    setPostAddCategory : (category)=>{
        ReactSession.set(post_add_category, category);
    },

    getPostAddCategory : ()=>{
        try {
            return ReactSession.get(post_add_category);
        } catch (e) {
            return null;
        }
    },

    clearPostAddParameter : ()=>{
        ReactSession.remove(post_add_category);
        ReactSession.remove(post_add_title);
    },







};

export default SessionConfig;