import React from 'react';
import './searchBanner.css';
import Seacrchbar from '../searchbar/SearchBarComponent'

import SliderImage1 from '../../../images/goinglocal-marketplace-header-slider-image-1.jpg';
import SliderImage2 from '../../../images/goinglocal-marketplace-header-slider-image-2.jpg';
import SliderImage3 from '../../../images/goinglocal-marketplace-header-slider-image-5.jpg';

export default function SearchBanner(props) {
    return(
        <div id="searchBar" style={{ backgroundImage : 'url('+SliderImage3+')'}}>

            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l12 center pt-5">
                        <h5 className="white-text">Buy more, spend less with</h5>
                        <h3 className="white-text">Going<span className="green-text">local™</span> Marketplace</h3>
                    </div>
                </div>
                <div className="row mt-3 pb-5">
                    <div className="col s12">
                        <Seacrchbar/>
                    </div>
                    <div className="col s12 white-text center">
                        <p>
                            Goinglocal™ - Australia's Premium Business Directory where we connect you with the locals!
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}