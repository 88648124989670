import React, {useEffect , useState} from 'react';
import PreviewJs from './add';
import './ad.css';
import AppConfig from "../../../config/AppConfig";
import axios from "axios/index";
import SessionConfig from "../../../config/SessionConfig";
import {useNavigate, useParams} from "react-router-dom/index";
import {Link} from "react-router-dom";
import { SocialIcon } from 'react-social-icons';
import UserIcon from '../../../images/usericon.png';
import RelatedAdsComponent from '../../backend/pages/related-ads/RelatedAdsComponent';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountConfig from "../../../config/AccountConfig";


export default function SingleAdComponent() {

    const [isLoading , setIsLoading] = useState(true);
    const [title , setTitle] = useState("");
    const [category , setCategory] = useState("");
    const [location , setlocation] = useState("");
    const [price , setPrice] = useState(0.00);
    const [minprice , setMinPrice] = useState(0.00);
    const [negotiable , setNegotiable] = useState(0);
    const [hasMinimumOffer , sethasMinimumOffer] = useState(0);
    const [condition , setCondition] = useState("");
    const [descriptions , setDescriptions] = useState("");
    const [shipping , setShipping] = useState("");
    const [featureImage, setFeatureImage] = useState(null);
    const [featureImagePath, setFeatureImagePath] = useState("");
    const [gallery, setGallery] = useState([]);
    const [catDetails , setcatDetails] = useState({});
    const [bc , setBc] = useState([]);
    const [isLightBoxOpen, setisLightBoxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [message , setMessage] = useState('');
    const {slug} = useParams();
    const [owner , setOwner] = useState({});
    const [related_ads , setRelatedAds] = useState([]);
    const [adID , setAdID] = useState([]);

    const [offer , setOffer] = useState("");

    const navigate = useNavigate();
    useEffect(()=>{
        window.scrollTo(0, 0);
        PreviewJs.js.initialize();
        getAd(slug);
    } , [slug]);

    const OnGalleryButtonClicked = (e , position)=>{
        setPhotoIndex(position);
        setisLightBoxOpen(true);
    };




    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    function getAd(slug) {
        setIsLoading(true);
        window.$('#savingLoadingScreen').show();
        axios.get(AppConfig.LINKS.VIEW_AD+"?slug="+slug, )
            .then(function (response) {
                setIsLoading(false);
                window.$('#savingLoadingScreen').hide();
                console.log(response);
                if(response.data.status === "OK"){
                    setAdID(response.data.listing.id);
                    setTitle(response.data.listing.name);
                    setCategory(response.data.listing.category);
                    setlocation(response.data.listing.location);
                    setPrice(response.data.listing.price);
                    setMinPrice(response.data.listing.min_price);
                    setNegotiable(response.data.listing.negotiable);
                    setCondition(response.data.listing.conditions);
                    setDescriptions(response.data.listing.descriptions);
                    setShipping(response.data.listing.shipping);
                    setFeatureImagePath(response.data.listing.feature_image);
                    setGallery(response.data.listing.gallery);
                    setcatDetails(response.data.listing.cat);
                    setOwner(response.data.listing.owner);
                    setRelatedAds(response.data.listing.related_ads);
                    bc.splice(0, bc.length);
                    bc.push({'name': "Home" , 'link' : '/'});
                    bc.push({'name': response.data.listing.location , 'link' : ''});
                    bc.push({'name': response.data.listing.cat.name , 'link' : ''});
                    bc.push({'name': 'Ad id - '+response.data.listing.uid , 'link' : ''});
                    setMessage('Hi, I\'m interested in the ad for "'+response.data.listing.name+'". When are you available and what do you charge? Thanks.');
                }


                if(response.data.status === "404"){
                   // navigate('/404', true);
                }
            })
            .catch(function (error) {
                window.$('#savingLoadingScreen').hide();
                console.log(error);
                setIsLoading(false);
            });
    }

    const OnhandleChange = (e) =>{
        const name =  e.target.name;
        const value =  e.target.value;

        switch (name){
            case 'offer':
                setOffer(value);
                break;
            case 'message':
                setMessage(value);
                break;

        }
    };

    const OnhandleMEssageSubmit = (e)=>{
        e.preventDefault();
        window.$('#savingLoadingScreen').show();
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        const formData = new FormData();
        formData.append('id' , adID );
        formData.append('message' , message );
        if(offer !== "" && offer !== null){formData.append('offer' , offer );}
        axios.post(AppConfig.LINKS.SEND_AD_MESSAGE, formData , config)
            .then(function (response) {
                console.log(response);
                window.$('#savingLoadingScreen').hide();
                if(response.data.message === 'OK'){
                    toast("Your message has been sent!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }

                if(response.data.message === 'ERROR'){
                    toast("An error occurred while sending your message", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
            });
    };

    const onHandleClick = (e)=>{
        e.preventDefault();
        const eID = e.currentTarget.id;
        switch (eID){
            case 'toLogin':
                navigate('/login' , true);
                break;
        }
    };

    return(

        <div className="preview-ad container pb-6" id="preview-ad">

            <ToastContainer />


            <div className="row mt-2 ">
                <div className="col s12">
                    {
                        Array(bc.length).fill(1).map((el, i) =>
                            <Link key ={i} className="breadcrumb" to={bc[i].link} >{bc[i].name}</Link>
                        )
                    }

                </div>
            </div>

            <div className="row mt-2">
                <div className="col s12 m12 l12">

                    <div className="">
                        <div className="cover-container">
                            <div className="bg-blur-holder">
                                <span style={{ backgroundImage: 'url('+AppConfig.ASSET_LINK+featureImagePath+')'}}></span>
                            </div>

                            <div className="media-actions-container">

                                <div className="social-share-btns hide-on-small-and-down">
                                    <SocialIcon network={'facebook'} fgColor={'#ffffff'}   style={{ height: 40, width: 40 }}/>
                                    <SocialIcon network={'instagram'} fgColor={'#ffffff'}   style={{ height: 40, width: 40 }}/>
                                    <SocialIcon network={'twitter'} fgColor={'#ffffff'}   style={{ height: 40, width: 40 }}/>
                                    <SocialIcon network={'mailto'} fgColor={'#ffffff'} bgColor={'#000'}   style={{ height: 40, width: 40 }}/>
                                </div>




                            </div>

                            <div className="feature-image-holder">
                                <img className="feature-image"  src={AppConfig.ASSET_LINK+featureImagePath} />
                            </div>


                        </div>

                        <div className="main-actions-container no-padding">
                            <div className=" pt-2">

                                <strong>Product Gallery ({gallery.length})</strong><br/>

                                {
                                    Array(gallery.length).fill(1).map((el, i) =>
                                        <img onClick={e => OnGalleryButtonClicked(e , i)} className="itm-image" key={i} src={gallery[i]} />
                                    )
                                }

                            </div>
                        </div>
                    </div>

                </div>
                <div className="col s12 m4 l4 padding-0 hide-on-med-and-down">
                    <div className="image-gallery-holder ">


                        <div className="row">
                            <div className="col s12 m12 l12 image-holder padding-0 hide">


                                {
                                    Array(gallery.length).fill(1).map((el, i) =>
                                        <img onClick={e => OnGalleryButtonClicked(e , i)} className="itm-image" key={i} src={gallery[i]} />
                                    )
                                }


                                {
                                    gallery.length > 0 ?
                                        <div className="" style={{ margin :5}} >
                                            <button onClick={ e => OnGalleryButtonClicked(e , 0)} className="white btn  black-text"><i className="material-icons left">photo_library</i> {gallery.length} Images</button>
                                        </div>
                                        : ''
                                }


                            </div>





                        </div>

                    </div>
                </div>
            </div>





            <div className="row mt-2">



                <div className="col s12 m12 l8">

                    <div className="white">


                        <div className="padding-2">
                            <h6 id="title">{title}</h6>
                            <p className="price-container mt-0 mb-0"><span id="price_amount" className="green-text">$ {numberWithCommas(price)}</span> { negotiable === 1 ? <small>Negotiable</small> : ''}</p>
                            <p className="mt-1 location-holder"><i className="material-icons flex ">location_on</i> {location} </p>
                        </div>

                        <div className="padding-4 pt-0">
                            <div className="row">
                                <div className="col s12 no-padding">
                                    <ul className="tabs product-tab">
                                        <li className="tab col m4 no-padding"><a href="#test1">Descriptions</a></li>
                                        <li className="tab col m4 no-padding"><a  href="#test2">Details</a></li>
                                    </ul>
                                </div>
                                <div id="test1" className="col s12 descriptions product0tab-content-holder" dangerouslySetInnerHTML={{__html: descriptions}} >

                                </div>
                                <div id="test2" className="col s12 product0tab-content-holder padding-2">
                                    <div className="row">
                                        <div className="col s12 m6 l6">
                                            <span className="black-text">Condition :</span> {condition}
                                        </div>
                                        <div className="col s12 m6 l6">
                                            <span className="black-text">Shipping :</span> { shipping}
                                        </div>

                                    </div>
                                    <div className="row mt-2">
                                        <div className="col s12 m6 l6">
                                            <span className="black-text">Category :</span> {catDetails.name}
                                        </div>
                                        <div className="col s12 m6 l6">
                                            <span className="black-text">Negotiable :</span> { negotiable === 1 ? 'YES' : 'NO'}
                                        </div>

                                    </div>

                                    <div className="row mt-2">

                                        <div className="col s12 m6 l6">
                                            <span className="black-text">Date Published :</span> Mon Feb  02, 2021
                                        </div>
                                        <div className="col s12 m6 l6">
                                            <span className="black-text">Last Edited :</span> Mon Feb  02, 2021
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>

                <div className="col s12 m12 l4">

                    <div className="white">
                        <div className="send-message-holder">

                            <div className="owner-info-holder">
                                <img src={UserIcon}/>
                                <div className="details">
                                    <p className="name mt-0 mb-0 text-capitalize">{ owner.first_name + " "+owner.last_name}</p>
                                    <p className="date mt-0 mb-0"><i className="material-icons flex green-text">perm_contact_calendar</i> Member since {owner.created_at}</p>
                                </div>
                            </div>

                            <form id="send-message-form" onSubmit={ e => OnhandleMEssageSubmit(e)}>
                                <div className="send-message-form-field-holder padding-4 pt-0">
                                    <span className="black-text">Message</span>
                                    <textarea name="message" onChange={e => OnhandleChange(e)} id="message" value={message}/>
                                    {
                                        negotiable === 1?
                                            <div className="mt-2">
                                                <span className="black-text">Make an offer</span>
                                                <div className="custom-input no-icon offerDivCon">
                                                    <input onChange={e => OnhandleChange(e)} style={{ paddingRight: 200}}  value={offer} min="1"  type="text" name="offer" id="offer" />
                                                    <span style={{ float: 'right' , marginTop: -45 , marginRight: 20}}>AUD</span>
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }

                                    {
                                        AccountConfig.AUTH.check() ?
                                            <div className="mt-1">
                                                <button type="submit" className="btn btn-large green width-100"><i className="material-icons flex">mail_outline</i> SEND MESSAGE</button>
                                            </div>

                                            :

                                            <div className="mt-1">
                                                <button id="toLogin" onClick={ e => onHandleClick(e)} type="button" className="btn btn-large green width-100"><i className="material-icons flex">lock</i> LOG IN TO SEND MESSAGE</button>
                                            </div>
                                    }


                                    <div className="phone-holder">

                                    </div>

                                </div>
                            </form>





                        </div>
                    </div>



                </div>











            </div>

            {
                related_ads.length > 0 ?
                    <RelatedAdsComponent relatedAds={related_ads} preview={true}/>
                    : ''
            }




            {isLightBoxOpen && (
                <Lightbox
                    mainSrc={gallery[photoIndex]}
                    nextSrc={gallery[(photoIndex + 1) % gallery.length]}
                    prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
                    onCloseRequest={()=>{setisLightBoxOpen(false)}}
                    onMovePrevRequest={
                        ()=>{setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)}
                    }
                    onMoveNextRequest={
                        ()=>{setPhotoIndex((photoIndex + 1) % gallery.length)}
                    }
                />
            )}

        </div>
    )
}