import React,{useEffect, useState} from 'react';
import './search-reult-list.css';

import SearchItem from './search-list-item/SeacrchListItemComponent';

export default function SearchResultList(props) {

    const [list , setList] = useState([]);

    useEffect(()=>{
        setList(props.list);
    } , []);

    return(
        <div id="search-result-list">
            <div className="row">
                <div className="col 12">


                    {
                        list.length > 0 ?
                            Array(list.length).fill(1).map((el, i) =>
                                <SearchItem key={i} listing={list[i]}/>
                            )
                            :

                            <div>
                                <h5>No items found.</h5>
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}