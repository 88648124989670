import AppConfig from '../../../../config/AppConfig';

const PostAddJs = {
    'js' : {
        initialize : ()=>{



            window.$('#offering_price').change(function () {
                if(window.$(this).is(":checked")){
                    window.$('#min_price').attr('required' , true);
                }else {
                    window.$('#min_price').attr('required' , false);
                }
            });




            window.$.ajax({
                url : AppConfig.LINKS.GET_CATEGORIES_LIST_BY_GROUP,
                type: 'get',
                success : function (response) {
                    //console.log(response);
                    window.$("#categoryselection").select2({
                        dropdownAutoWidth: true,
                        width: '100%',
                        data: JSON.parse(response),
                        placeholder: 'Search category by name...',
                        templateResult : formatState,
                        templateSelection: formatSelection
                    });
                },
                error: function (e) {
                    console.log(e);
                }
            });


            window.$("#locationselection").select2({
                minimumInputLength: 2,
                placeholder: 'Suburb or Postcode',
                ajax: {

                    url : AppConfig.LINKS.GET_LOCATIONS,
                    dataType: "json",
                    type: "GET",
                    data: function (params) {

                        const queryParameters = {
                            key: params.term
                        };
                        return queryParameters;
                    },
                    processResults: function (data) {
                        //console.log(data)
                        return {
                            results: window.$.map(data, function (item) {
                                return {
                                    text: item.text,
                                    id: item.id,
                                    state: item.state,
                                    postcode: item.postcode
                                }
                            })
                        };
                    }
                }
            });















        }
    }
};

function init() {

    window.$(document).find('.catItemParent').mouseenter(function () {
        window.$(this).removeClass('z-depth-0');

        window.$(this).mouseleave(function () {
            window.$(this).addClass('z-depth-0');
        });
    });

    window.$(document).find('.catItemParent').click(function () {
        const catp = window.$(this).data('parent');
        alert(catp.parent.name);
    });
}

function formatState (state) {
    if (!state.id) {
        return state.text;
    }
    var $state = window.$(
        '<span class="green-text parent-category"> '+(state.content.icon != null ? '<i class="material-icons">'+state.content.icon+'</i>' : "")+' ' + state.text + '</span>'
    );

    var $stat2 = window.$(
        '<span> ' + state.text + '</span>'
    );

    if(state.content.icon != null){
        return $state;
    }else {
        return $stat2;
    }



};


function formatSelection(state) {
    if (!state.id) {
        return state.text;
    }



    var $state = window.$(
        '<span class="green-text"> '+(state.parent.icon != null ? '<i class="material-icons">'+state.parent.icon+'</i>' : "")+' ' + state.parent.name + ' <span class="grey-text">• ' + state.text + '</span></span>'
    );

    return $state;
}




export default PostAddJs;