import React, {useState , useEffect} from 'react';
import  axios from 'axios';
import AccountConfig from "../../../../config/AccountConfig";
import SessionConfig from "../../../../config/SessionConfig";
import AppConfig from "../../../../config/AppConfig";
import {useNavigate, useParams} from "react-router-dom/index";
import PostAdjs from './editad';
import  './editadd.css';
import imageTemp600x300 from '../../../../images/image-temp-600x300.jpg';
import { Editor} from '@tinymce/tinymce-react';
import RUG from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from '../../../components/LoadingScreen';


export default function EditAdComponent() {

    const navigate = useNavigate();
    const screenheight = window.innerHeight;
    const {uid} = useParams();

    useEffect(()=>{
        if(!AccountConfig.AUTH.check()){
            navigate('/login', true);
        }

        if(!AccountConfig.AUTH.isVerified()){
            navigate('/unverified');
        }


        PostAdjs.js.initialize();

        window.$('#categoryselection').on("select2:selecting", function(e) {
            setCategory(e.params.args.data.id);
        });

        window.$('#locationselection').on("select2:selecting", function(e) {
            setlocation(e.params.args.data.id);
        });

        getListingData();



    } , [uid]);

    const [listing , setListing] = useState(null);

    const [title , setTitle] = useState("");
    const [category , setCategory] = useState("");
    const [location , setlocation] = useState("");
    const [price , setPrice] = useState(0.00);
    const [minprice , setMinPrice] = useState(0.00);
    const [negotiable , setNegotiable] = useState(0);
    const [hasMinimumOffer , sethasMinimumOffer] = useState(0);
    const [condition , setCondition] = useState("");
    const [descriptions , setDescriptions] = useState("");
    const [shipping , setShipping] = useState("");
    const [featureImage, setFeatureImage] = useState(null);
    const [featureImagePath, setFeatureImagePath] = useState("");
    const [featureImageGallery, setFeatureImageGallery] = useState([]);
    const [status, setStatus] = useState('draft');
    const [newstatus, setNewStatus] = useState('draft');
    const [isLoading, setisLoading] = useState(true);


    const HeaderToken = { Authorization: `Bearer ${SessionConfig.getToken()}` };

    const initialState = [];


    const OnHandleChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'title':
                setTitle(value);
                break;
            case 'price':
                setPrice(value);
                break;
            case 'min_price':
                setMinPrice(value);
                break;
            case 'condition':
                setCondition(value);
                break;
            case 'descriptions':
                setDescriptions(value);
                break;
            case 'shipping':
                setShipping(value);
                break;
            case 'featured_imaged':
                console.log(e.target.files);
                setFeatureImage(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = function (event) {

                    window.$('#imagePrev').attr("src" , event.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                break;
            case 'negotiable':
                if(window.$("#isNegotiable").is(":checked")){
                    window.$('.negotiableitems').show();
                    setNegotiable(1);
                }else {
                    window.$('.negotiableitems').hide();
                    setNegotiable(0);
                }
                break;

            case 'offering_price':
                if(window.$("#offering_price").is(":checked")){
                    window.$('#min_price').attr('required' , true);
                    sethasMinimumOffer(1);
                }else {
                    window.$('#min_price').attr('required' , false);
                    sethasMinimumOffer(0);
                }
                break;
            case 'readyToPublished':
                if(window.$("#readyToPublished").is(":checked")){
                    setNewStatus("published");
                }else {
                    setNewStatus("draft");
                }
                break;
            case 'changestat':
                setNewStatus(value);
                break;

        }
    };

    const OnHandleSubmit = (e)=>{
        e.preventDefault();

        const content = window.$(descriptions);
        const contenttext = window.$(content).text();
        const len =  contenttext.length;

        if(len > 5000){
            alert("Descriptions field must not exceed 5000 characters!");
            return false;
        }

        window.$('#savingLoadingScreen').show();

        const formData = new FormData();
        formData.append('uid' , uid );
        formData.append('name' , title );
        formData.append('location' ,  location);
        formData.append('category' ,  category);
        formData.append('price' , price );
        formData.append('min_price' , minprice);
        formData.append('negotiable' , negotiable );
        formData.append('condition' , condition  );
        formData.append('descriptions' , descriptions );
        formData.append('shipping' ,  shipping);
        formData.append('status' ,  newstatus);
        if(featureImage != null ){
            formData.append('feature_image' ,  featureImage);
        }

        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        axios.post(AppConfig.LINKS.UPDATE_LISTING, formData,config)
            .then(function (response) {
                window.$('#savingLoadingScreen').hide();
                console.log(response);
                if(response.data.status === "OK"){

                    toast(response.data.message, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    setStatus(response.data.listing.status);
                }
            })
            .catch(function (error) {
                window.$('#savingLoadingScreen').hide();
                console.log(error);
            });
    };

    const handleEditorChange = (e) => {
        setDescriptions(e.target.getContent());
    };

    function getListingData() {
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        window.$('#savingLoadingScreen').show();
        axios.get(AppConfig.LINKS.GET_LISTING_DATA+"?uid="+uid, config)
            .then(function (response) {
                window.$('#savingLoadingScreen').hide();
                //console.log(response);
                if(response.data.status === "OK"){
                    setTitle(response.data.listing.name);
                    setCategory(response.data.listing.category);
                    window.$('#categoryselection').val([response.data.listing.category]).trigger('change');
                    setlocation(response.data.listing.location);
                    const option = window.$('<option selected>'+response.data.listing.location+'</option>').val(response.data.listing.location);
                    window.$('#locationselection').append(option).trigger('change');
                    setPrice(response.data.listing.price);
                    setMinPrice(response.data.listing.min_price);
                    setNegotiable(response.data.listing.negotiable);
                    setCondition(response.data.listing.conditions);
                    setDescriptions(response.data.listing.descriptions);
                    setShipping(response.data.listing.shipping);
                    setFeatureImagePath(response.data.listing.feature_image);
                    sethasMinimumOffer( (response.data.listing.min_price != null ? 1 : 0));
                    setFeatureImageGallery(response.data.listing.gallery);
                    setStatus(response.data.listing.status);
                    setNewStatus(response.data.listing.status);
                    setisLoading(false);
                }

                if(response.data.status === "404"){
                    navigate('404', true);
                    setisLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
                setisLoading(false);
            });
    }

    function deleteImage(id) {
        const formData = new FormData();
        formData.append('id' , id );
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        axios.post(AppConfig.LINKS.DELETE_IMAGE_FROM_GALLERY, formData,config)
            .then(function (response) {
                window.$('#savingLoadingScreen').hide();
                console.log(response);
                if(response.data.status === "OK"){
                    toast(response.data.message, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch(function (error) {
                window.$('#savingLoadingScreen').hide();
                console.log(error);
            });
    }


    return(

        <div className="container" style={{ minHeight: screenheight -215 ,  paddingBottom: 50}}>
            <div className="row">
                <div className="col s12">
                    <h2 className="center mt-5 mb-5">Edit Ad</h2>
                </div>
            </div>

            <form className="" onSubmit={e => OnHandleSubmit(e)}>
                <div className="row">
                    <div className="col s12 m12 l12 white pt-3 pb-5 pl-2 pr-2">
                        <div className="row">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Enter a title</span>
                                <p className="mt-0 mb-0">Longer and descriptive titles get more visibility!</p>
                                <div className="custom-input no-icon">
                                    <input required type="text" value={title} name="title" id="title" onChange={ e => OnHandleChange(e)}/>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Select category</span>
                                <div className="custom-input no-icon select">
                                    <select required={true}   className="browser-default select2-data-ajax " id="categoryselection" name="category">
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Select Location</span>
                                <div className="custom-input no-icon select">
                                    <select required={true}   className="browser-default select2-data-ajax " id="locationselection" name="location">
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col s12 m5 l4">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Price</span>
                                <div className="custom-input no-icon">
                                    <input style={{ paddingRight: 200}} value={price} required type="text" name="price" id="price" onChange={ e => OnHandleChange(e)}/>
                                    <span style={{ float: 'right' , marginTop: -45 , marginRight: 20}}>AUD</span>
                                </div>
                            </div>
                            <div className="col s12 m7 l8 negotiable-div">
                                <label>
                                    <input type="checkbox" checked={ (negotiable === 1 ? true : false) } className="filled-in" name="negotiable" id="isNegotiable" onChange={e => OnHandleChange(e)}/>
                                    <span>Negotiable</span>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-1 negotiableitems" style={{ display: (negotiable == 1 ? 'block' : 'none' ) }}>
                            <div className="col s12 ">
                                <label>
                                    <input type="checkbox" checked={ (hasMinimumOffer == 1 ? true : false)} className="filled-in" name="offering_price" id="offering_price" onChange={e => OnHandleChange(e)}/>
                                    <span>Would you like to set a minimum offer amount?</span>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-2 negotiableitems" style={{ display: (negotiable == 1 ? 'block' : 'none' ) }}>
                            <div className="col s12 m5 l4">
                                <div className="custom-input no-icon">

                                    <input type="text" required={(hasMinimumOffer == 1 ? true : false)} name="min_price" id="min_price" value={minprice} onChange={ e => OnHandleChange(e)}/>
                                    <span style={{ float: 'right' , marginTop: -45 , marginRight: 20}}>AUD</span>
                                </div>
                            </div>
                            <div className="col s12 m7 l6 black-text" style={{ paddingTop: 5}}>
                                <strong>Note:</strong> You will not receive offers/notifications
                                below the minimum offer amount.
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Condition</span>
                                <label style={{ display: 'inline-block' , marginRight: 20, marginLeft: 20}}>
                                    <input required={true} name="condition" checked={ (condition === 'used' ? true : false) } type="radio"  value='used' onChange={ e => OnHandleChange(e) } />
                                    <span>Used</span>
                                </label>
                                <label>
                                    <input required={true} name="condition" checked={ (condition === 'new' ? true : false) } type="radio" value='new' onChange={ e => OnHandleChange(e) }  />
                                    <span>New</span>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12 m12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Descriptions</span>
                                <br/>
                                <span>Max 5000 characters</span>
                                <Editor
                                    apiKey='8r59yeqi0ckti09lyqp6werj0iyp7dloff5ez9cxa1otewoj'
                                    initialValue={descriptions}
                                    init={{
                                        max_chars: 5,
                                        menubar : false,
                                        plugins: 'lists',
                                        toolbar: 'undo redo | bold italic numlist bullist',
                                        paste_as_text: true,
                                        setup: function(editor) {

                                            editor.on('keyup', function(e) {

                                                //console.log('edited. Contents: ' + editor.getContent());
                                                const content = window.$(editor.getContent());
                                                const contenttext = window.$(content).text();
                                                const len =  contenttext.length;

                                                if(len > 5000){
                                                    window.$('#charNum').text("Max characters reached!");
                                                    window.$('#charNum').addClass("red-text");
                                                }else {
                                                    window.$('#charNum').text(""+(5000 - len)+" characters left");
                                                    window.$('#charNum').removeClass("red-text");
                                                }
                                            });

                                        }
                                    }}
                                    onChange={ e => handleEditorChange(e)}
                                />
                                <span id="charNum" style={{ display: 'inline-block' , marginTop: 10}} >5000 characters left</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <hr className="mt-2 mb-2 grey border-1" style={{ opacity: '0.3'}}/>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Shipping</span>
                                <label style={{ display: 'inline-block' , marginRight: 20, marginLeft: 20}}>
                                    <input required={true} name="shipping" checked={ (shipping === 'pick-up' ? true : false) } type="radio" value='pick-up' onChange={ e => OnHandleChange(e) } />
                                    <span>Pickup Only</span>
                                </label>
                                <label>
                                    <input required={true} name="shipping" checked={ (shipping === 'ship-and-pickup' ? true : false) } type="radio" value='ship-and-pickup' onChange={ e => OnHandleChange(e) }/>
                                    <span>Shipping & Pickup</span>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col s12 m4 l4 pt-3">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Ad featured image</span>
                                <p className="mt-0">- For best results, we recommend choosing landscape images.</p>
                                <img src={ (featureImagePath != null && featureImagePath != '' ? AppConfig.ASSET_LINK+featureImagePath : imageTemp600x300)} id="imagePrev" style={{ width: '100%', height: 170, objectFit : 'cover'}}/>
                                <div className="input-file-field-con">

                                    <input required={(featureImagePath != null && featureImagePath != '' ? false : true)} type="file" accept="image/jpg, image/jpeg, image/png"  name="featured_imaged" id="featured_imaged" onChange={ e => OnHandleChange(e)}/>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <ToastContainer />

                <div className="row white mt-4">
                    <div className="col s12 m12 l12 pt-3">
                        <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Ad image gallery</span>
                        <p className="mt-0 mb-0">- For best results, we recommend choosing landscape images.</p>
                        <p className="mt-0">- Upload up to 8 images.</p>


                        {
                            !isLoading ?

                                <RUG
                                    action = {AppConfig.LINKS.UPLOAD_TO_GALLERY+'?uid='+uid}
                                    initialState={(featureImageGallery != null ? featureImageGallery : [])}
                                    type = 'card'
                                    autoUpload={false}
                                    onSuccess={(image)=> {
                                        //console.log("success");
                                        //console.log(image);
                                    }}
                                    onError={(status, response, image)=>{
                                        //console.log(image);
                                    }}
                                    rules = {{ limit: 10}}
                                    source={function (response) {
                                        return response.source
                                    }}
                                    headers = {HeaderToken}
                                    sorting = {false}
                                    onDeleted = {function (image) {
                                        deleteImage(image.id)
                                    }}
                                /> : ''
                        }
                    </div>


                </div>

                {
                    status === 'draft'?
                        <div className="row  mt-2">
                            <div className="col s12 m12 l12 padding-2 white">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Is your ad ready to be published?</span>
                                <br/>

                                <label style={{ display: 'inline-block' , marginRight: 20, marginTop: 10}}>
                                    <input  name="readyToPublished" className="filled-in" id="readyToPublished" type="checkbox" value='published' onChange={ e => OnHandleChange(e) } />
                                    <span>Yes, publish this ad.</span>
                                </label>

                                <div className="row mt-2">
                                    <div className="col s12">
                                        <button className="btn btn-large green">SAVE CHANGES</button>
                                    </div>
                                </div>

                            </div>

                        </div>


                        :
                        <div className="row  mt-2">
                            <div className="col s12 m12 l12 padding-2 white">
                                <span className="black-text" style={{ fontWeight: 600, fontSize: 18}}>Change Ad status</span>
                                <br/>
                                <label style={{ display: 'inline-block' , marginRight: 20, marginTop: 10}}>
                                    <input  name="changestat" checked={ newstatus === 'published' } type="radio" value='published' onChange={ e => OnHandleChange(e) } />
                                    <span>Published</span>
                                </label>
                                <br/>
                                <label style={{ display: 'inline-block' , marginRight: 20, marginTop: 10}}>
                                    <input  name="changestat"  checked={ newstatus === 'draft' } type="radio" value='draft' onChange={ e => OnHandleChange(e) } />
                                    <span>Draft</span>
                                </label>
                                <br/>
                                <label style={{ display: 'inline-block' , marginRight: 20, marginTop: 10}}>
                                    <input  name="changestat"  checked={ newstatus === 'sold' } type="radio" value='sold' onChange={ e => OnHandleChange(e) } />
                                    <span>Sold Out</span>
                                </label>

                                <div className="row mt-2">
                                    <div className="col s12">
                                        <button className="btn btn-large green">SAVE CHANGES</button>

                                    </div>
                                </div>

                            </div>

                        </div>
                }
























            </form>

        </div>
    )
}