import SessionConfig from './SessionConfig';

const user = "USER_DATA";

const AccountConfig = {

    AUTH : {

        check : ()=>{
            if(SessionConfig.getToken() != null){
                return true;
            }else {
                return false;
            }
        },

        authenticate : (_token)=>{
            SessionConfig.setToken(_token);
        },

        destroy : ()=>{
            SessionConfig.destroy_token();
        },

        user : ()=>{
            return SessionConfig.getUser();
        },

        isVerified: ()=>{

            try {
                const user = SessionConfig.getUser();
                if(user.verified != null && user.verified !== 0){
                    return true;
                }else {
                    return false;
                }
            }catch(e){
                return false;
            }



        },

        verifyEmail : ()=>{
            const user = SessionConfig.getUser();
            user.verified = 1;
            SessionConfig.setUser(user);
        },



    }

};

export default AccountConfig;