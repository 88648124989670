import React from 'react';
import { Spinner  } from 'loading-animations-react';

export default function LoadingScreen() {
    const screenheight = window.innerHeight;
    return(
        <div className="loadingscreen" style={{ minHeight: screenheight -215 ,  paddingBottom: 50}}>

            <div className="valign-wrapper green-text" style={{ height: screenheight -215}}>
                <p style={{ width: 100, margin: 'auto'}}>
                    <Spinner color1="#00bb00" color2="#43a047" textColor="rgba(0,0,0, 0.5)" />
                </p>
            </div>

        </div>
    )
}