import React from 'react';
import SearchBanner from '../../components/seacrhBanner/SearchbannerComponent';
import SearchBarComponent from "../../components/seacrhBanner/SearchbannerComponent";
import './homepage.css';

import SliderImage1 from '../../../images/goinglocal-marketplace-header-slider-image-1.jpg';
import SliderImage2 from '../../../images/goinglocal-marketplace-header-slider-image-2.jpg';
import SliderImage3 from '../../../images/goinglocal-marketplace-header-slider-image-5.jpg';
import SliderImage4 from '../../../images/goinglocal-marketplace-header-slider-image-6.jpg';
import SliderImage5 from '../../../images/goinglocal-marketplace-header-slider-image-7.jpg';
import SliderImage6 from '../../../images/goinglocal-marketplace-header-slider-image-8.jpg';


import RecentAds  from './recent-ads/RecentAdsComponent';


export default function Home(props) {
    return(
       <div id="Homepage">
           <SearchBarComponent/>

           <RecentAds/>


           <div className="container pt-4 pb-4">
               <div className="row">
                   <div className="col s12">
                       <h4>Featured Categories</h4>
                       <p>Browse through some of our most popular categories</p>
                   </div>
               </div>
               <div className="row">
                   <div className="col s12 m4 l3 mt-4">
                       <div className="f-cat-item">
                           <img src={SliderImage4}/>
                           <div className="f-cat-details-holder">
                               <h5  className="white-text mb-0">Cars</h5>
                               <p className="mt-0 green-text">10,000 ads</p>
                           </div>
                       </div>
                   </div>
                   <div className="col s12 m4 l3 mt-4">
                       <div className="f-cat-item">
                           <img src={SliderImage5}/>
                           <div className="f-cat-details-holder">
                               <h5  className="white-text mb-0">Home & Garden</h5>
                               <p className="mt-0 green-text">9,000 ads</p>
                           </div>
                       </div>
                   </div>
                   <div className="col s12 m4 l3 mt-4">
                       <div className="f-cat-item">
                           <img src={SliderImage6}/>
                           <div className="f-cat-details-holder">
                               <h5  className="white-text mb-0">Jobs</h5>
                               <p className="mt-0 green-text">16,000 ads</p>
                           </div>
                       </div>
                   </div>
                   <div className="col s12 m4 l3 mt-4">
                       <div className="f-cat-item">
                           <img src={SliderImage1}/>
                           <div className="f-cat-details-holder">
                               <h5  className="white-text mb-0">Service For Hire</h5>
                               <p className="mt-0 green-text">4,000 ads</p>
                           </div>
                       </div>
                   </div>


               </div>
           </div>


       </div>
    )
}