const RegisterJS = {
    'form' :{
        initializeForm : ()=>{
            window.$("#subscribeBox").prop('checked' , true);
            window.$("#agreeBox").prop('checked' , true);

            window.$("#registration-form").validate({
                rules: {
                    name: {
                        required: true,
                    },
                    email: {
                        required: true,
                        email:true
                    },
                    password: {
                        required: true,
                        minlength: 8
                    },
                    password_confirmation: {
                        required: true,
                        minlength: 8,
                        equalTo: "#password"
                    },

                },
                messages: {
                    password_confirmation:{
                        equalTo: "Your passwords don't match!",
                        minlength: "Enter at least 8 characters"
                    },
                },

                errorElement : 'div',
                errorPlacement: function(error, element) {
                    const placement = window.$(element).data('error red-text');
                    if (placement) {
                        window.$(placement).append(error)
                    } else {
                        error.insertAfter(element);
                    }
                }
            });
        }
    }
};

export default RegisterJS;