import React, {useEffect , useState} from 'react';
import mylistingJs from './mylisting';
import './mylistings.css';
import Header from '../../header/_headerComponent';
import SideNav from '../../sidenav/_sidenavComponent';
import AccountConfig from "../../../../config/AccountConfig";
import SessionConfig from '../../../../config/SessionConfig';
import AppConfig from '../../../../config/AppConfig';
import ListItem from './listItem/ListItemComponent';
import axios from "axios";
import {useNavigate} from "react-router-dom/index";
import {Link, useParams} from "react-router-dom";

export default function MyListingsComponent(props) {

    const navigate = useNavigate();
    const [pageNum , setPageNum] = useState("");
    const queryParams = new URLSearchParams(window.location.search);
    const  linkPage = (queryParams.get("page") != null && queryParams.get("page") != '' ? parseInt(queryParams.get("page")) : 1);
    const {status} = useParams();

    useEffect(()=>{
        if(!AccountConfig.AUTH.check()){
            navigate('/login');
        }else {
            mylistingJs.js.initialize();
            getMyAds();
        }
    } , [status , linkPage]);

    const page = {"page" : 'my-ads' , "name" : 'My Ads' };
    const bc = [{'name' : 'Home' , 'link' : '/'} ,{'name' : 'My Ads' , 'link' : '/user/ads'}];
    const [myads , setMyAds] = useState([]);
    const [totalPage , setTotalPages] = useState(0);
    const [activePage , setactivePage] = useState(0);
    let currentPage = 0;
    let lastPage = 0;
    const [myAdsResponce , setmyAdsResponce] = useState([]);

    const OnHandleChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'status':

                switch (value){
                    case 'all':
                        navigate('/user/ads');
                        break;
                    case 'published':
                        navigate('/user/ads/published');
                        break;
                    case 'draft':
                        navigate('/user/ads/draft');
                        break;
                    case 'trash':
                        navigate('/user/ads/trash');
                        break;
                }


                break;
        }
    };

    function truncate(input) {
        if (input.length > 30) {
            return input.substring(0, 30) + '...';
        }
        return input;
    }


    function getMyAds() {
        window.$('#savingLoadingScreen').show();
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        axios.get(AppConfig.LINKS.ADS_BY_STATUS+"?status="+status+'&&page='+linkPage, config, )
            .then(function (response) {
                window.$('#savingLoadingScreen').hide();
                console.log(response);
                if(response.data.status === "OK"){
                    setMyAds(response.data.ads.data);
                    currentPage = response.data.ads.current_page;
                    lastPage = response.data.ads.last_page;
                    setTotalPages(lastPage);
                    setactivePage(currentPage);
                }
            })
            .catch(function (error) {
                window.$('#savingLoadingScreen').hide();
                console.log(error);
            });
    }

    return(
        <div className="container" style={{ paddingTop: 20 , paddingBottom: 50}}>
            <div className="row">
                <div className="col s12 m4 l3">
                    <SideNav page={page} />
                </div>
                <div className="col s12 m8 l9">
                    <Header page={page} breadcrumbs={bc}/>
                    
                    <div className='mt-3'>
                        <label style={{ display: 'inline-block', margin: 10 , marginLeft: 0}}>
                            <input name="status" type="radio"  value='all' checked={ (status == 'all' || status == null ? true : false)}  onChange={e => OnHandleChange(e)}/>
                            <span>All</span>
                        </label>
                        <label style={{ display: 'inline-block', margin: 10 , marginLeft: 0}}>
                            <input name="status" type="radio"  value='published' checked={ (status == 'published' ? true : false)} onChange={e => OnHandleChange(e)}/>
                            <span>Published</span>
                        </label>
                        <label style={{ display: 'inline-block', margin: 10 , marginLeft: 0}}>
                            <input name="status" type="radio" value='draft'  checked={ (status == 'draft' ? true : false)} onChange={e => OnHandleChange(e)}/>
                            <span>Drafts</span>
                        </label>
                        <label style={{ display: 'inline-block', margin: 10 , marginLeft: 0}}>
                            <input name="status" type="radio" value='trash' checked={ (status == 'trash' ? true : false)} onChange={e => OnHandleChange(e)} />
                            <span>Trash</span>
                        </label>
                    </div>

                    <div className="mt-2">
                        <table className=" white striped Highlight responsive-table" >
                            <tbody id="adsTable" className="">
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                {
                                    myads.length > 0 ?
                                        Object.keys(myads).map((key, i) => (
                                            <tr key={key} className="data-tr">
                                                <td>{truncate(myads[i].name)}</td>
                                                <td>{myads[i].category}</td>
                                                <td>{myads[i].status}</td>
                                                <td className="">
                                                    <Link to={'/preview/'+myads[i].uid} className="btn-action green-text">Preview</Link>&nbsp;
                                                    <Link to={'/edit-ad/'+myads[i].uid} className="btn-action blue-text">Edit</Link> &nbsp;
                                                    <Link to="" className="btn-action red-text">Move to Trash</Link>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={4} className="">No ads found.</td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                        <div className="mt-6">
                            <ul className="pagination mt-3" >
                                <li className={" "+(linkPage == 1 ? "disabled" : "" )}>
                                    <Link  to={(linkPage > 1 ? "?page="+(linkPage-1) : "?page="+(linkPage))}><i className="material-icons flex">chevron_left</i></Link>
                                </li>

                                {Array(totalPage).fill(1).map((el, i) =>
                                    <li key={i} className={"waves-effect "+(linkPage == (i+1) ? "active" : "" )}><Link to={"?page="+(i+1)}>{i+1} </Link></li>
                                )}


                                <li className={""+(activePage == totalPage ? "disabled" : "" )}>
                                    <Link to={(linkPage < totalPage ? "?page="+(parseInt(linkPage+1)) : "?page="+(linkPage))}><i className="material-icons flex">chevron_right</i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}