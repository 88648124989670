const MyListingJS = {
    'js' : {
        initialize : ()=>{

            // Collapsible inside page not for sidebar
            const allCollapse = document.querySelectorAll(".collapsible");
            window.M.Collapsible.init(allCollapse);
            // Collapsible expandable
            const elem = document.querySelector('.collapsible.expandable');
            const instance = window.M.Collapsible.init(elem, {
                accordion: false
            });
        }
    }
};

export default MyListingJS;