const domain = "http://test.crm.marketplace.goinglocal.com.au/";

const AppConfig = {

    'ASSET_LINK' : 'http://test.crm.marketplace.goinglocal.com.au/',
    'GA_TRACKING_ID' : '',

    'LINKS' : {
        "LOGIN" : domain+"api/login",
        "REGISTER" : domain+"api/register",
        "LOGOUT" : domain+"api/logout",
        "UPDATE_PASSWORD" : domain+"api/updatePassword",
        "FORGOT_PASSWORD" : domain+"api/forgot-password",
        "CHANGE_PASSWORD" : domain+"api/change-password",
        "RESEND_VERIFICATION_EMAIL" : domain+"api/resendVerificationEmail",
        "CHECK_VERIFICATION_EMAIL" : domain+"api/checkVerification",

        "PROFILE" : domain+"api/profile",
        "UPDATE_PROFILE" : domain+"api/updateProfile",
        "UPDATE_PROFILE_PASSWORD" : domain+"api/updatePassword",

        "GET_CATEGORIES" : domain+'api/getCategories',
        "GET_CATEGORIES_BY_GROUP" : domain+'api/getCatsGroup',
        "GET_CATEGORIES_LIST_BY_GROUP" : domain+'api/getCatsListGroup',

        "ADS" : domain+'api/listings',
        "PREVIEW_AD" : domain+'api/previewAd',
        "VIEW_AD" : domain+'api/viewAd',
        "ADS_BY_STATUS" : domain+'api/getMyAdsByStatus',
        "ADD_LISTING" : domain+'api/addListing',
        "UPDATE_LISTING" : domain+'api/updateListing',
        "GET_LISTING_DATA" : domain+'api/getListingData',
        "UPLOAD_TO_GALLERY" : domain+'api/uploadAdImageGallery',
        "DELETE_IMAGE_FROM_GALLERY" : domain+'api/deleteImageFromGallery',
        "SEND_AD_MESSAGE" : domain+'api/sendMessage',
        "SEARCH_LISTING_BY_KEYWORD" : domain+'api/search/listing',
        "GET_RECENT_ADS" : domain+'api/getRecentAds',
        "SEARCH_LISTING" : domain+'api/searchListings',

        "GET_LOCATIONS" : domain+'api/getLocations',
        "GET_LOCATIONS_LIST" : domain+'api/getLocations/list',
    },

    'SOCIAL_IDS' : {
        'REACT_APP_GG_APP_ID' : '787425054836-r8f6o96s2te7etr7kpffmf209q76pi6u.apps.googleusercontent.com',
        'REACT_APP_INSTAGRAM_APP_ID' : '',
        'REACT_APP_INSTAGRAM_APP_SECRET' : '',
        'REACT_APP_LINKEDIN_APP_SECRET' : '',
        'REACT_APP_LINKEDIN_APP_ID' : '',
        'REACT_APP_TWITTER_APP_ID' : '',
        'REACT_APP_TWITTER_API_KEY' : '',
        'REACT_APP_TWITTER_APP_SECRET' : '',
        'REACT_APP_TWITTER_TOKEN' : '',
    }

};

export default AppConfig;