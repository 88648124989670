import React, {useCallback, useState , useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import AppConfig from '../../../config/AppConfig';
import AccountConfig from '../../../config/AccountConfig';
import SessionConfig from '../../../config/SessionConfig';

import {
    LoginSocialGoogle,
    IResolveParams,
} from 'reactjs-social-login';
import {
    FacebookLoginButton,
    GoogleLoginButton,
    LinkedInLoginButton,
} from 'react-social-login-buttons';
import axios from "axios/index";






export default function Login(props) {

    let navigate = useNavigate();

    useEffect(()=>{
        if (AccountConfig.AUTH.check()){
            navigate('/');
        }
    } , []);

    const screenheight = window.innerHeight;
    const [email , setEmail] = useState("");
    const [password , setPassword] = useState("");
    const [errorsAccount, seterrorsAccount] = useState([]);

    const handleOnSubmit = (e)=>{
        e.preventDefault();
        window.$('#savingLoadingScreen').show();
        window.$('#submitBtn').attr('disabled' ,  true);
        window.$('#submitBtn').text('Loading...');
        const formData = new FormData();
        formData.append('email' , email );
        formData.append('password' , password );
        axios.post(AppConfig.LINKS.LOGIN, formData)
            .then(function (response) {
                console.log(response);
                window.$('#savingLoadingScreen').hide();
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('LOG IN');
                if(response.data.message === 'OK'){
                    AccountConfig.AUTH.authenticate(response.data.access_token);
                    SessionConfig.setUser(response.data.user);
                    navigate('/user/profile');
                    window.$('#resultTxt').text('');
                    window.$('#resultTxtHolder').hide();
                }

                if(response.data.message === 'BAD'){
                    window.$('#resultTxtHolder').show();
                    window.$('#resultTxt').text(response.data.content);
                }
            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('LOG IN');
                seterrorsAccount(error.response.data.errors);
            });
    };


    const handleOnChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;

        }
    };


    const onLoginStart = useCallback(() => {
    }, [])

    return(
        <div className="login-page" id="login-page" style={{ minHeight: screenheight -99 ,  paddingBottom: 50}}>


            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l10 push-l1    register-form-main-holder">


                        <div className="row equal-height">

                            <div className="col s12 m6 l6   padding-4 min-height-100" style={{  paddingBottom: 70}}  >

                                <p style={{ marginTop: 10, marginBottom: 0, fontWeight: 400, color: '#999'}}>Welcome back!</p>
                                <h4 style={{ marginTop: 0, marginBottom: 1}}>Log in account </h4>
                                <p style={{marginTop: 5, marginBottom: 0, fontWeight: 400, color: '#999'}}>Don't have an account? <Link to="/register" className="green-text">Register here</Link></p>

                                <form style={{ marginTop: 30}} onSubmit={e => handleOnSubmit(e)}>

                                    <strong>Email Address<span className="red-text">*</span></strong>
                                    <div className="custom-input">
                                        <i className="material-icons dp48">mail_outline</i>
                                        <input required type="email" onChange={e => handleOnChange(e)} name="email" placeholder="youremail@domain.com..."/>
                                    </div>
                                    <strong>Password<span className="red-text">*</span></strong>
                                    <div className="custom-input" style={{ marginBottom: 5}}>
                                        <i className="material-icons dp48">lock_outline</i>
                                        <input required  type="password" onChange={e => handleOnChange(e)} name="password" placeholder="type your password here..."/>
                                    </div>
                                    <Link to="/forgot-password" className="green-text">Forgot password?</Link>

                                    <div className="card-alert  gradient-45deg-red-pink mt-2" id="resultTxtHolder" style={{ display: 'none'}}>
                                        <div className="card-content white-text">
                                            <i className="material-icons">error</i>
                                            <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">

                                            </p>


                                        </div>

                                    </div>

                                    <button id="submitBtn" className="btn btn-large green width-100 mt-3">Log In</button>
                                    <br/>
                                    <div className="center ">
                                        <p>Copyright © 2022 Going Local</p>
                                    </div>

                                </form>

                            </div>
                            <div className="social-buttons-container col s12 m6 l6   center-align-sm left-align-lg" style={{ padding: 50}}>

                                <p className="center-align-sm left-align-lg" style={{ marginTop: 10, marginBottom: 10, fontWeight: 400, color: '#000'}}>Or connect with:</p>


                                <LoginSocialGoogle
                                    client_id={AppConfig.SOCIAL_IDS.REACT_APP_GG_APP_ID}
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }: IResolveParams) => {
                                        //setProvider(provider)
                                        //setProfile(data)

                                        console.log(data);
                                    }}
                                    onReject={(err) => {
                                        console.log(err)
                                    }}
                                    >
                                    <GoogleLoginButton />
                                </LoginSocialGoogle>

                                <LoginSocialGoogle
                                    client_id={AppConfig.SOCIAL_IDS.REACT_APP_GG_APP_ID}
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }: IResolveParams) => {
                                        //setProvider(provider)
                                        //setProfile(data)

                                        console.log(data);
                                    }}
                                    onReject={(err) => {
                                        console.log(err)
                                    }}
                                >
                                    <FacebookLoginButton />
                                </LoginSocialGoogle>

                                <LoginSocialGoogle
                                    client_id={AppConfig.SOCIAL_IDS.REACT_APP_GG_APP_ID}
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }: IResolveParams) => {
                                        //setProvider(provider)
                                        //setProfile(data)

                                        console.log(data);
                                    }}
                                    onReject={(err) => {
                                        console.log(err)
                                    }}
                                >
                                    <LinkedInLoginButton />
                                </LoginSocialGoogle>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}