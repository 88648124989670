import React, {useState, useEffect} from 'react';
import searchBarJS from './searchbar';
import './serachbar.css';
import AppConfig from "../../../config/AppConfig";
import axios from 'axios';
import {Link} from "react-router-dom";
import {useNavigate} from 'react-router-dom';

export default function SearchBar(props) {



    useEffect(()=>{
        searchBarJS.js.initialize();

        if(categories.length === 0){
            getCategories();
        }

    } , []);

    let navigate = useNavigate();
    const [category , setCategory] = useState(['All Categories' , 'all-categories']);
    const [keyword , setkeyword] = useState('');
    const [location , setlocation] = useState('');
    const [categories , setCategories] = useState([]);
    const [isLoading , setIsLoading] = useState(true);
    const [searchResults , setSearchResults] = useState([]);
    const [locationSearchResults , setlocationSearchResults] = useState([]);

    function getCategories() {
        setIsLoading(true);
        axios.get(AppConfig.LINKS.GET_CATEGORIES_LIST_BY_GROUP)
            .then(function (response) {
                //console.log(response);
                setIsLoading(false);
                setCategories(response.data);
            })
            .catch(function (e) {
                console.log(e);
                setIsLoading(false);
            });
    }

    const OnlocationReusultItemLCiked = (e , value)=>{
        setlocation(value);
        window.$('.location-result-holder').addClass('hide');
    };

    const OnKeyReusultItemLCiked = (e , value)=>{
        setkeyword(value);
        window.$('.keyword-result-holder').addClass('hide');
    };


    const onHandleClick = (e, value)=>{
        e.preventDefault();
        const eID = window.$(e.target).attr('class');
        switch (eID){
            case 'category-item':
                setCategory(value);
                window.$('.category-selection-holder').addClass("hide");
                window.$('#select-category-trigger i').css({'transform' : 'rotate('+ 0 +'deg)'});
                break;

        }
    };

    function truncate(input) {
        if (input.length > 10) {
            return input.substring(0, 10) + '...';
        }
        return input;
    }

    const onChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;

        switch (name){
            case 'keyword':
                setkeyword(value);
                if([...value].length > 2){
                    searchKey(value)
                }
                break;
            case 'location':
                setlocation(value);
                if([...value].length > 2){
                    searchLocation(value)
                }
                break;
        }
    };

    function searchKey(key) {
        axios.get(AppConfig.LINKS.SEARCH_LISTING_BY_KEYWORD+"?keyword="+key)
            .then(function (response) {
                //console.log(response);
                setSearchResults([]);
                setSearchResults(response.data.data);
            })
            .catch(function (e) {
                console.log(e);
            });
    }

    function searchLocation(key) {
        axios.get(AppConfig.LINKS.GET_LOCATIONS_LIST+"?key="+key)
            .then(function (response) {
                //console.log(response);
                setlocationSearchResults([]);
                setlocationSearchResults(response.data);
            })
            .catch(function (e) {
                console.log(e);
            });
    }

    function highLightKeys(words, key) {
        const regex = new RegExp(key, "i");
        if (key.search(regex) !== -1) {
            return words.replace(regex, "<b class='black-text'>$&</b>");
        }
    }

    const OnHandleSubmit = (e)=>{
        e.preventDefault();
        //navigate();
        window.location.href = '/search?category='+category[1]+'&&name='+keyword+'&&location='+location;
    };





    return(
        <div id="serachbar" className={isLoading ? 'hide' : ''}>

            <form onSubmit={e => OnHandleSubmit(e)}>
            <div className="row ">
                <div className=" col s12 m4 l3 green white-text sb-category-holder">
                    <p id="select-category-trigger" style={{ margin : '13.4px 5px'}}>
                        <i className="material-icons left mr-2" style={{ marginTop: -2}}>list</i>
                        <span id="category-value" >{truncate(category[0])}</span>
                        <i className="material-icons right" style={{ marginTop: -2}}>chevron_right</i>
                    </p>

                    <div className="category-selection-holder hide " id="category-list-main-holder">
                        <ul className={ 'z-depth-0 mt-0 mb-0 collapsible collapsible-accordion'+(!isLoading ? '' : 'hide') }>
                            {
                                Array(categories.length).fill(1).map((el, i) =>


                                    i === 0 ? '' :
                                        <li key={i}>
                                            <div className="collapsible-header green-text"><i className="material-icons left">{categories[i].content.icon}</i> {categories[i].text}</div>
                                            <div className="collapsible-body no-padding">
                                                {
                                                    Array(categories[i].children.length).fill(1).map((el, j) =>

                                                        <div  onClick={e => onHandleClick(e , [categories[i].children[j].text , categories[i].children[j].id])}  key={j} className="category-item">
                                                            {categories[i].children[j].text}
                                                        </div>

                                                    )
                                                }
                                            </div>
                                        </li>

                                )

                            }
                        </ul>
                    </div>

                </div>
                <div className=" col s12 m3 l4 white keyword-holder">
                    <input required={true} autoComplete={'off'} value={keyword} id="keyword-search-input" name="keyword" onChange={e => onChange(e)} type="text" className="browser-default" placeholder="I'm looking for..."/>

                    <div className="keyword-result-holder hide">
                        <ul className="keyword-result-itel-list">
                            {
                                searchResults.length > 0 ?
                                Array(searchResults.length).fill(1).map((el, i) =>



                                    searchResults[i].type === "category" ?
                                    <li key={i} >
                                        <Link to={'/'+searchResults[i].slug+'/'+searchResults[i].keyword}>
                                            <span className="black-text">{searchResults[i].keyword}</span><br/>
                                            <span className="green-text">in {searchResults[i].category}</span>
                                        </Link>
                                    </li>
                                        :



                                        <li key={i} onClick={e => OnKeyReusultItemLCiked(e , searchResults[i].keyword )}>
                                            <span className="" dangerouslySetInnerHTML={{__html: highLightKeys(searchResults[i].keyword , keyword)}} />
                                        </li>
                                )

                                    : ''

                            }

                        </ul>
                    </div>

                </div>
                <div className=" col s12 m3 l3 white location-selection-holder ">
                    <input required={true} autoComplete={'off'} value={location} id="location-search-input" name="location" onChange={e => onChange(e)} type="text" className="browser-default" placeholder="Suburb or Postcode"/>

                    <div className="location-result-holder hide">
                        <ul className="keyword-result-itel-list">
                        {
                            locationSearchResults.length > 0 ?
                                Array(locationSearchResults.length).fill(1).map((el, i) =>

                                    <li key={i} className="location-result-item" onClick={e => OnlocationReusultItemLCiked(e , locationSearchResults[i].id )} >
                                        <span  className="location-result-item" dangerouslySetInnerHTML={{__html: highLightKeys(locationSearchResults[i].id , location)}} />
                                    </li>

                                )

                                : ''

                        }
                        </ul>
                    </div>
                </div>
                <div className="col s12 m2 l2 green submit-btn-holder">
                    <button className=" btn green width-100 z-depth-0 ">Search</button>

                </div>
            </div>
            </form>


        </div>
    )
}