import React, {useEffect , useState} from 'react';
import './profile.css';
import './profile';
import axios from 'axios';
import AccountConfig from '../../../../config/AccountConfig';
import  {useNavigate, useParams} from 'react-router-dom';
import SessionConfig from '../../../../config/SessionConfig';
import AppConfig from '../../../../config/AppConfig';


import Header from '../../header/_headerComponent';
import SideNav from '../../sidenav/_sidenavComponent';

export default function Profile(props) {

    const page = {"page" : 'profile' , "name" : 'Profile' };
    const bc = [{'name' : 'Home' , 'link' : '/'} ,{'name' : 'Profile' , 'link' : '/user/profile'}];

    const [firstname , setFirstName] = useState("");
    const [lastname , setLastName] = useState("");
    const [email , setEmail] = useState("");
    const [phoneNumber , setPhoneNumber] = useState("");
    const [address , setAddress] = useState("");
    const [address_1 , setAddress_1] = useState("");

    const navigate = useNavigate();
    let {verified} = useParams();
    let {token} = useParams();


    useEffect(()=>{
        window.initializeLocationSelector();
        if(!AccountConfig.AUTH.check()){
            navigate('/login');
        }else {

            if (verified != null && token !=null && !AccountConfig.AUTH.isVerified()){
                checkVerification();
            }else {
                if(!AccountConfig.AUTH.isVerified()){
                    navigate('/unverified');
                }else {
                    getProfile();

                    if(!SessionConfig.isUserMailVerificationNotified()){
                        const strdateverified = AccountConfig.AUTH.user().email_verified_at;
                        const dateVerified = new Date(strdateverified);
                        const dateNow = new Date();
                        const diffTime = Math.abs(dateVerified - dateNow);
                        const toeldiffTime = diffTime / 1000 / 60;
                        if(toeldiffTime < 5){
                            window.$('#successInfo').show();
                        }
                    }


                }
            }


        }

        window.$('#suburbSelection').on("select2:selecting", function(e) {
            setAddress_1(e.params.args.data.text);
        });

        window.$('.close-notif-btn').on("click", function(e) {
            window.$('#successInfo').hide();
            SessionConfig.setUserVerificationNotified();
        });


    } , []);

    function getProfile() {
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        window.$('#savingLoadingScreen').show();
        axios.get(AppConfig.LINKS.PROFILE,config)
            .then(function (response) {
                //console.log(response);
                window.$('#savingLoadingScreen').hide();
                setFirstName(response.data.user.first_name);
                setLastName(response.data.user.last_name);
                setEmail(response.data.user.email);
                setPhoneNumber((response.data.user.phone_number != null && response.data.user.phone_number !== 'null' ? response.data.user.phone_number : '' ));
                setAddress((response.data.user.address != null && response.data.user.address !== 'null' ? response.data.user.address : '' ));
                setAddress_1((response.data.user.address_1 != null && response.data.user.address_1 !== 'null' ? response.data.user.address_1 : '' ));
                window.$('#suburbSelection').val([response.data.user.address_1]).trigger('change');
                const option = window.$('<option selected>'+response.data.user.address_1+'</option>').val(response.data.user.address_1);
                window.$('#suburbSelection').append(option).trigger('change');

            })
            .catch(function (error) {
                //console.log(error);
                window.$('#savingLoadingScreen').hide();
            });



    }

    function checkVerification() {
        const formData = new FormData();
        formData.append('token' , token );
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        window.$('#savingLoadingScreen').show();
        axios.post(AppConfig.LINKS.CHECK_VERIFICATION_EMAIL, formData , config)
            .then(function (response) {
                console.log(response);
                window.$('#savingLoadingScreen').hide();

                const message = response.data.message;
                if(message === 'OK'){
                    window.$('#successInfo').show();
                    SessionConfig.setUser(response.data.user);
                    SessionConfig.isUserMailVerificationNotified();
                    AccountConfig.AUTH.verifyEmail();
                    getProfile();
                }

            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
            });

    }

    const onChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'first_name' :
                setFirstName(value);
                break;
            case 'last_name' :
                setLastName(value);
                break;
            case 'email' :
                setEmail(value);
                break;
            case 'phone_number' :
                setPhoneNumber(value);
                break;
            case 'address' :
                setAddress(value);
                break;
            case 'address_1' :
                setAddress_1(value);
                break;

        }
    };

    const onSubmit = (e)=>{
        e.preventDefault();
        window.$('#savingLoadingScreen').show();
        const formData = new FormData();
        formData.append('first_name' , firstname );
        formData.append('last_name' , lastname );
        formData.append('email' , email );
        formData.append('phone_number' , phoneNumber );
        formData.append('address' , address );
        formData.append('address_1' , address_1 );
        const config = {
            headers: { Authorization: `Bearer ${SessionConfig.getToken()}` }
        };
        axios.post(AppConfig.LINKS.UPDATE_PROFILE , formData , config)
            .then(function (response) {
                console.log(response);
                window.$('#savingLoadingScreen').hide();
                if(response.data.message === "OK"){
                    window.$('#successInfo').show();
                }

                if(response.data.message === "BAD"){
                    window.$('#errorInfo').show();
                }


            })
            .catch(function (error) {
                console.log(error);
                window.$('#savingLoadingScreen').hide();
            });
    };

    return(
        <div className="container" style={{ paddingTop: 20 , paddingBottom: 50}}>
            <div className="row">
                <div className="col s12 m4 l3">
                    <SideNav page={page} />
                </div>
                <div className="col s12 m8 l9">
                    <Header page={page} breadcrumbs={bc}/>

                    <div className="card-alert card gradient-45deg-green-teal z-depth-0" id="successInfo" style={{ display: 'none'}}>
                        <div className="card-content white-text">
                            <p>
                                <i className="material-icons">check</i> &nbsp;&nbsp; Your account has been verified.
                            </p>
                        </div>
                        <button type="button" className="close white-text close-notif-btn" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>



                    <form onSubmit={e => onSubmit(e)}>
                        <div className="card mt-3">

                            <div className="card-content">
                                <span className="card-title mb-3">Personal Details</span>
                                <div className="row">
                                    <div className="col s12 m6 l6">
                                        <strong>First Name<span className="red-text">*</span></strong>
                                        <div className="custom-input no-icon">
                                            <input required type="text" onChange={ e => onChange(e)} value={firstname} name="first_name" id="first_name"/>
                                        </div>
                                    </div>
                                    <div className="col s12 m6 l6">
                                        <strong>Last Name<span className="red-text">*</span></strong>
                                        <div className="custom-input no-icon">
                                            <input required type="text" onChange={ e => onChange(e)} value={lastname}  name="last_name" id="last_name"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col s12 m6 l6">
                                        <strong>Email Address<span className="red-text">*</span></strong>
                                        <div className="custom-input no-icon">
                                            <input disabled={true} value={email} onChange={ e => onChange(e)} required type="email"  name="email" id="email"/>
                                        </div>
                                    </div>
                                    <div className="col s12 m6 l6">
                                        <strong>Phone No.</strong>
                                        <div className="custom-input no-icon">
                                            <input   type="text" onChange={ e => onChange(e)} value={phoneNumber} name="phone_number" id="phone_number"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">

                            <div className="card-content">
                                <span className="card-title mb-3">Address</span>
                                <div className="row">
                                    <div className="col s12 m6 l7">
                                        <strong>Street Address</strong>
                                        <div className="custom-input no-icon">
                                            <input  type="text" onChange={ e => onChange(e)} value={address}  name="address" id="address"/>
                                        </div>
                                    </div>
                                    <div className="col s12 m6 l5">
                                        <strong>Select Suburb<span className="red-text">*</span></strong>
                                        <div className="custom-input no-icon select">
                                            <select required={true}   className="browser-default select2-data-ajax " id="suburbSelection" name="address_1">
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="mt-3">

                            <div className="mb-3 card-alert  gradient-45deg-green-teal mt-2" id="successInfo" style={{ display: 'none'}}>
                                <div className="card-content white-text">
                                    <i className="material-icons">check</i>
                                    <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">
                                        Changes have been saved.
                                    </p>
                                </div>

                            </div>

                            <div className="mb-3 card-alert  gradient-45deg-red-pink mt-2" id="errorInfo" style={{ display: 'none'}}>
                                <div className="card-content white-text">
                                    <i className="material-icons">error</i>
                                    <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">
                                        Error occurred when updating profile.
                                    </p>
                                </div>

                            </div>

                            <button className="btn btn-large green">SAVE CHANGES</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}