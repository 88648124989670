import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AdListItem from '../../../components/ad-list-item/AdListitemComponent';
import AppConfig from "../../../../config/AppConfig";

export default function RecentAds() {


    useEffect(()=>{
        getRecentAds();
    } , []);

    const [recentAds , setrecentAds] = useState([]);

    function getRecentAds() {
        axios.get(AppConfig.LINKS.GET_RECENT_ADS)
            .then((response)=>{
                console.log(response);
                setrecentAds([]);
                setrecentAds(response.data.data.chunk(4));
            })
            .catch((error)=>{
                console.log(error);
            })
    }

    return(
        <div className="pb-4 pt-4 white">
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <h4>Recent Ads</h4>
                        <p>Be the first to make deals with these new ads.</p>
                    </div>
                </div>


                {
                    recentAds.length > 0 ?

                        Array(recentAds.length).fill(1).map((el, i) =>
                            <div className="row" key={i}>

                                {
                                    Array(recentAds[i].length).fill(1).map((el2, j) =>
                                        <div key={j} className="col s12 m3 l3 mt-2">
                                            <AdListItem item={recentAds[i][j]}/>
                                        </div>
                                    )
                                }
                            </div>
                        )


                        :

                        ""
                }


            </div>

        </div>
    )
}