import React from 'react';
import './_sidenav.css';
import './_sidenav';
import {Link, useNavigate} from "react-router-dom";
import UserProfile from './user/UserProfileComponent';
import AccountConfig from "../../../config/AccountConfig";

export default function SideNav(props) {
    const navigate = useNavigate();
    const logout = (e)=>{
        e.preventDefault();
        AccountConfig.AUTH.destroy();
        navigate('/login');
    };

    return(
        <div className="z-depth-0" style={{ padding: 20}}>
            <UserProfile/>
            <small >Navigation Menu</small >
            <ul className="mt-0 _sidenav_links">
                <li className={ (props.page.page === 'profile' ? 'active' : '') }><Link to="/user/profile"> <i className="material-icons flex" style={{ fontSize: 20}}>face</i> &nbsp;Profile</Link></li>
                <li className={ (props.page.page === 'my-ads' ? 'active' : '') }><Link to="/user/ads"><i className="material-icons flex" style={{ fontSize: 20}}>list</i> &nbsp;My Ads</Link></li>
                <li className={ (props.page.page === 'settings' ? 'active' : '') }><Link to="/user/settings"> <i className="material-icons flex" style={{ fontSize: 20}}>settings</i> &nbsp;Settings</Link></li>
                <li><a href="#" onClick={e => logout(e)} ><i className="material-icons flex" style={{ fontSize: 20}}>exit_to_app</i> &nbsp;Log Out</a></li>
            </ul>
        </div>
    )
}